import { API, graphqlOperation} from 'aws-amplify';
import {getApplicantDB, listApplicantDBS,listDialysisDBS} from '../graphql/queries';
import * as ApplicantState from "../States/ApplicantState";
import * as EditApplicantState from "../States/EditApplicantState";
import * as DialysisState from '../States/DialysisState';
import * as ReportState from '../States/ReportState';
import {setapplicantsForSameMobileNoshowAlert,setError,setpatientIDCount} from '../States/GlobalState';
import {handleErrorCloseButton} from "../Helpers/Alert";
import {todayDate, getNewIssueDate} from "../Helpers/General";
import {editApplicantEntry} from "./DatabaseUpdates";

export const fetchApplicantUsingApplicantNoForEditApplicant = async () => {
    try{
      let input = {"applicantID": EditApplicantState.FormValue.details.applicantID};
        let response = await API.graphql(graphqlOperation(getApplicantDB, input));
        if(response.data.getApplicantDB){
          return response.data.getApplicantDB;
        }
        else{
          setError({showError:true,errorMsg:"Patient ID not found! Please verify and try again.",typeOfError:"ApplicantIDNotFoundInDB"});
          setTimeout(handleErrorCloseButton,2000);
        }

    } catch (e) {
        console.log("Error In Catch: ", e);

    }
};

export const fetchApplicantUsingCenterAssignedForDownload = async (centerAssignedValue, date1, date2) => {
  try{
    let response = await API.graphql(graphqlOperation(listApplicantDBS, { filter: { centerAssigned: { eq: centerAssignedValue }, date: { between: [date1, date2] } } }));
    if (response.data.listApplicantDBS.items) {
      return response.data.listApplicantDBS.items;
    }
    else {
      setError({ showError: true, errorMsg: "No records Found! Please verify and try again.", typeOfError: "ApplicantIDNotFoundInDB" });
      setTimeout(handleErrorCloseButton, 2000);
    }

  } catch (e) {
    console.log("Error In Catch: ", e);

  }
};


export const fetchDialysisHistoryUsingApplicantID = async (value) => {
  try {
    let input = { "applicantID": value };
    let response1 = await API.graphql(graphqlOperation(getApplicantDB, input));
    if (response1.data.getApplicantDB && response1.data.getApplicantDB.centerAssigned.includes("Center")) {
      let date1 = response1.data.getApplicantDB.issuedOn;
      let date2 = todayDate;
      let newIssueDate = getNewIssueDate(date1)
      if (newIssueDate.NewIssueDateStatus === true) {
        let dataToSent = { "applicantID": value, "issuedOn": newIssueDate.NewIssueDateProperFormat }
        let res = await editApplicantEntry(dataToSent);
        if (res) {
          response1.data.getApplicantDB.issuedOn = newIssueDate.NewIssueDateProperFormat;
          DialysisState.setapplicantDetails(response1.data.getApplicantDB);
          ReportState.setcomponentSetup("ApplicantHistory");
          return true;
        }
      } else {
        let response2 = await API.graphql(graphqlOperation(listDialysisDBS, { filter: { applicantID: { eq: value }, date: { between: [date1, date2] } } }));
        if (response2.data.listDialysisDBS.items) {
          DialysisState.setapplicantDetails(response1.data.getApplicantDB);
          DialysisState.setfetchedDataA(response2.data.listDialysisDBS.items);
          ReportState.setcomponentSetup("ApplicantHistory");
          return true;
        }
      }
    }
  } catch (e) {
    console.log("Error In Catch: ", e);
  }
};

export const fetchDialysisHistoryUsingMobileNo = async (value) => {
    try{
        let response1 = await API.graphql(graphqlOperation(listApplicantDBS, {filter: {mobileNo: {eq: value}}}));
        if(response1.data.listApplicantDBS.items){
          let data = response1.data.listApplicantDBS.items;
          if(data.length>1) {
            let array = [];
            data.map(eachData=>{
              if(eachData.centerAssigned==="Center"){
                array.push(eachData);
              }
              return array;
            })
            if(array.length>1){
              DialysisState.setapplicantsForSameMobileNo({showAlert:false, itemList: array});
              setapplicantsForSameMobileNoshowAlert(true);
              return true;
            }else if(array.length===1){
              await fetchDialysisHistoryUsingApplicantID(array[0].applicantID)
            }
          }
          else {
            if(data[0].centerAssigned==="Center"){
              await fetchDialysisHistoryUsingApplicantID(data[0].applicantID)
            }
           
          }
        }
        else{
          return false;
        }
    } catch (e) {
        console.log("Error In Catch: ", e);
        return false;
    }
};



export const fetchPatientID = async () => {
    try{
      let input = {"applicantID": "PatientID"};
      let response = await API.graphql(graphqlOperation(getApplicantDB, input));
      if(response.data.getApplicantDB){
        let newPatientID = response.data.getApplicantDB.applicantName;
        setpatientIDCount(newPatientID);
        ApplicantState.setapplicantID(newPatientID);
        // setpatientIDCount("1");
        // ApplicantState.setapplicantID("1");
      }
    } catch (e) {
      console.log("error in fetchPatientID:",e);
    }
};

export const fetchDialysisHistoryUsingCenter = async (centerAssigned,nextTokenValue,date1,date2) => {
    try{

        let response = await API.graphql(graphqlOperation(listDialysisDBS, { filter: { centerAssigned: { eq: centerAssigned }, date: { between: [date1, date2] } }, nextToken:nextTokenValue}));
        let items = response.data.listDialysisDBS.items;
        let nextToken = response.data.listDialysisDBS.nextToken;
        return {items,nextToken};
    } catch (e) {
        console.log("Error In Catch: ", e);
    }
};

export const fetchApplicantUsingApplicantNameForEditApplicant = async (name) => {
  try{
      let response = await API.graphql(graphqlOperation(listApplicantDBS, {filter: {applicantName: {contains: name}}}));
      if(response.data.listApplicantDBS){
        return response.data.listApplicantDBS.items;
      }
      else{
        setError({showError:true,errorMsg:"Patient not found! Please verify the name and try again.",typeOfError:"ApplicantNameNotFoundInDB"});
        setTimeout(handleErrorCloseButton,2000);
      }

  } catch (e) {
      console.log("Error In Catch: ", e);

  }
};