import {observer} from "mobx-react-lite";
import * as ReportState from "../States/ReportState";
import DataSearch from "./HelperComp/DataSearch";
import ApplicantHistory from "./HelperComp/ApplicantHistory";
import NewDialysisEntry from "./HelperComp/NewDialysisEntry";

const DialysisHistory = () => {
  let {componentSetup} = ReportState.FormValue;
  if(componentSetup === "DataSearch"){
    return (<DataSearch/>)
  }
  else if(componentSetup === "ApplicantHistory"){
    return (<ApplicantHistory/>)
  }
  else if(componentSetup === "NewDialysisEntry"){
    return (<NewDialysisEntry/>)
  }
};

export default observer(DialysisHistory);
