
import {TextField, Autocomplete} from "@mui/material";
import {observer} from "mobx-react-lite";
import * as DialysisState from "../../States/DialysisState";

import {handleApplicantSearchByName, handleNameSelectedFromDropDown} from "../../Helpers/DialysisHistoryProcessing";

const fetchNameDataCalled = (nameData) => {
    let newName = nameData.toUpperCase();
    DialysisState.setnameData(newName);
    if(nameData.length === 0){
        DialysisState.clearFields();
    }else if(nameData.length === 3){
        DialysisState.setnameData(newName);
        handleApplicantSearchByName();
    }else if(nameData.length <3){
        DialysisState.setoptionData([]);
        DialysisState.setnameOption([]);
    }   
}

const NameSearchInReports = () => {
    return (
        <Autocomplete
            id="InputputnameAutocomp"
            style={{width:'25vw',marginTop:'10px'}}
            options= {DialysisState.FormValue.nameSearch.nameOption}
            autoSelect
            openOnFocus
            value={DialysisState.FormValue.nameSearch.nameSelected}
            renderInput={(params) => 
                <TextField {...params} 
                    id="inputNameTextfield" 
                    label="Name" 
                    variant="outlined"
                    onChange={(e)=> fetchNameDataCalled(e.target.value)} 
                />}
            onChange={(event,newValue)=>{handleNameSelectedFromDropDown(newValue)}}   
        />
	)
};

export default observer(NameSearchInReports);