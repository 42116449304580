import {Dialog,DialogContent,CircularProgress,Typography} from "@mui/material";
import {GlobalState} from "../States/GlobalState";
import {observer} from "mobx-react-lite";


const LoadingModal = () => {
	return (
		<>
		{GlobalState.loadingModal ?
			<Dialog onClose={()=>{}} open={true} PaperProps={{style:{background:"#00000000",boxShadow:"none"}}}>
				<DialogContent  style={{padding:'0px',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
				<CircularProgress style={{margin:'10px'}}/>
				<Typography>{}</Typography>
			</DialogContent>
		</Dialog>
		: null
		}
		</>
	);
};

export default observer(LoadingModal);
