import {Auth} from "aws-amplify";
import {GlobalState, setResetPassword, setAccount, setScreen, setError,setcenterAssignedValue,setPassword,setloadingModal,setResetError} from "../States/GlobalState";
import {handleErrorCloseButton} from "../Helpers/Alert";

export const checkUserIsLoggedIn = async () => {
	try {
		const user = await Auth.currentAuthenticatedUser();
		let groupName = user.signInUserSession.accessToken.payload["cognito:groups"][0];
		setAccount(user,groupName);
		if(groupName === "admin") {
			setScreen("AdminPage")
		}
		else if (groupName === "center") {
			setScreen("CenterPage")
			setcenterAssignedValue(user.username)
		}
	} catch (error) {
		setScreen("SignInSignUpPage");
		// setError({showError:true,errorMsg:error,typeOfError:"UserNotFound"});
		// setTimeout(handleErrorCloseButton,2000);
	}
};

export const signingIn = async () => {
	let {username, password} = GlobalState.login;
	setloadingModal(true);
	try {
		let user = await Auth.signIn(username, password);
		if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
			setResetPassword(true);
		} else if(user.signInUserSession){
			let groupName =  user.signInUserSession.accessToken.payload["cognito:groups"][0];
			setAccount(user,groupName);
			if(groupName === "admin") setScreen("AdminPage");
			else if (groupName === "center") {
				setScreen("CenterPage");
				setcenterAssignedValue(user.username);
			}
		}
		setloadingModal(false);
	} catch (e) {
		setloadingModal(false);
		setError({showError:true,errorMsg:"Incorrect username or password",typeOfError:"UserNotFound"});
		setPassword("");
		setTimeout(handleErrorCloseButton,1000);
	}
};

export const signingOut = async () => {
	try {
		await Auth.signOut();
		setScreen("SignInSignUpPage")
	}
	catch (e) {
		console.log('error signing out: ',e);
		setError({showError:true,errorMsg:e,typeOfError:"UserNotFound"});
	}
};

export const resetPassword = async () => {
	let {username, password} = GlobalState.login;
	let {newPassword1, newPassword2} = GlobalState.resetPassword;
	setloadingModal(true);
	if (newPassword1 === newPassword2) {
		try {
			let user = await Auth.signIn(username,password);
			await Auth.completeNewPassword(user,newPassword1);
			if(user){
				let groupName =  user.signInUserSession.accessToken.payload["cognito:groups"][0];
				setAccount(user,groupName);
				if(groupName === "admin") setScreen("AdminPage");
				else if (groupName === "center") setScreen("CenterPage");
				setResetPassword(false);
			}
			setloadingModal(false);
	}
	catch(e){
		setloadingModal(false);
		setResetError(false);
		setError({showError:true,errorMsg:"There was some error. Try again",typeOfError:"ResetPasswordError"});
		setTimeout(handleErrorCloseButton,1000);
	}
} else {
		setloadingModal(false);
		setResetError(false);
		setError({showError:true,errorMsg:"Passwords are not matching. Try again",typeOfError:"ResetPasswordError"});
		setTimeout(handleErrorCloseButton,1000);
	}
};
