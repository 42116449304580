import { API, graphqlOperation, Storage } from 'aws-amplify';
import Axios from "axios";
import { createApplicantDB, updateApplicantDB, createDialysisDB} from "../graphql/mutations";
import * as NewApplicantState from "../States/ApplicantState";
import * as EditApplicantState from "../States/EditApplicantState";
import {GlobalState} from "../States/GlobalState";

export const newApplicantEntry = async (FormDataNew) => {
    try{
        let response = await API.graphql(graphqlOperation(createApplicantDB, {input:FormDataNew}));
        let resArray = ["Success", response];
        return resArray
    } catch (e) {
        let resArray = ["Error", e];
        return resArray
    }
};

export const editApplicantEntry = async (FormDataEdit) => {
    try{
      await API.graphql(graphqlOperation(updateApplicantDB, {input:FormDataEdit}));
        let response = await API.graphql(graphqlOperation(updateApplicantDB, {input:FormDataEdit}));
        let resArray = ["Success", response];
        return resArray
    } catch (e) {
        console.log("Error In Catch: ", e);
    }
};

export const saveNewImagesOnS3 = async (Name,BlobArray,form) => {
  if(BlobArray.length>0){
    let imageBlobs = GetImagesFromBlob(Name,BlobArray);
    let id;
    if(form==="new"){
      id = NewApplicantState.FormValue.details.applicantID;
    }else{
      id = EditApplicantState.FormValue.details.applicantID;
    }
    if(imageBlobs.length>0){
      let responses = await Promise.all(imageBlobs);
      await Promise.all([uploadImagesFromBlob(responses,id)])
    }
    return true;
  }else{
    return true;
  }
};

export const uploadImagesFromBlob = async (responses,id)=>{
    let keyArray=[];
    for (let i=0;i<responses.length;i++){
        responses[i].image.then(async(result)=>{
             let res = await Storage.vault.put(`${id}/${responses[i].fileName}`,result.data,{contentType: "image/jpeg"});
             keyArray.push(res);
        });
    }
    return keyArray;
};

export const GetImagesFromBlob = (Name,BlobArray) => {
    let blobs = [];
    if(Name === "documents"){
      if(BlobArray.length>0){
        BlobArray.forEach((url)=>{
          if(url.includes("blob")){
            let fileName = getFileNameFromImageURL(url);
            let image = Axios.get(url,{responseType:"blob"});
            blobs.push({image:image,fileName});
          }
        })
      }
    }
    else if(Name === "applicantPhoto"){
      if(BlobArray.includes("blob")){
        let fileName = getFileNameFromImageURL(BlobArray);
        let image = Axios.get(BlobArray,{responseType:"blob"});
        blobs.push({image:image,fileName});
      }
    }
    return blobs;
};

export const getFileNameFromImageURL = (url) => {
    let splitArray = url.split("/");
    let splitArray2 = splitArray[splitArray.length-1].split("-");
    return `${splitArray2[0]}-${splitArray2[1]}`;
};

export const newDialysisEntry = async (FormDataNewEntry) => {
    try{
        let response = await API.graphql(graphqlOperation(createDialysisDB, {input:FormDataNewEntry}));
        return response;
    } catch (e) {
        console.log("Error In Catch: ", e);
        return false;
    }
};

export const updatePatientID = async () => {
  let newPatientIDCount = Number(GlobalState.patientIDCount)+1;
  let newPatientIDCountString = newPatientIDCount.toString();
  let FormData = {"applicantID": "PatientID","applicantName": newPatientIDCountString,"mobileNo" : "newPatientIDCountInApplicantName","centerAssigned" : "(PatientID) is the primary key"};
    try{
        let response = await API.graphql(graphqlOperation(updateApplicantDB, {input:FormData}));
        return response;
    } catch (e) {
        console.log("Error In Catch: ", e);
        return false;
    }
};


export const addPatientID = async () => {
  let newPatientIDCount = 0
  let newPatientIDCountString = newPatientIDCount.toString();
  let FormData = {"applicantID": "PatientID","applicantName": newPatientIDCountString,"mobileNo" : "newPatientIDCountInApplicantName","centerAssigned" : "(PatientID) is the primary key"};
    try{
        let response = await API.graphql(graphqlOperation(createApplicantDB, {input:FormData}));
        return response;
    } catch (e) {
        console.log("Error In Catch: ", e);
        return false;
    }
};
