import {observable, action, computed} from "mobx";
// import {setcenterAssigned} from "./ReportState";
// 	login: {username: "garimapshah", password: "1234qwer@", error: false, inProgress: false},
export const GlobalState = observable({
	login: {username: "", password: "", error: false, inProgress: false},
	resetPassword: {
		newPassword1: "",
		newPassword2: "",
		inProgress: false
	},
	auth: {
		isLoggedIn: "",
		userDetails: {},
		group: "",
		isChecking: "",
		isResetPassword: ""
	},
	isOffline: false,
	screen:"AdminPage",
	errorObj: {
		errorMsg:"",
		showError:false,
		typeOfError:""
	},
	successObj: {
		successMsg:"",
		showSuccess:false,
		typeOfSuccess:""
	},
	applicantsForSameMobileNoshowAlert: false,
	patientIDCount:"0",
	loadingModal:false,
	centerAssignedValue:""
});

//computed
export const canWeSignIn = computed(() => {
	const {username, password} = GlobalState.login;
	let status;
	if (username !== "" && password !== "") {
		status = true;
	} else {
		status = false;
	}
	return status;
});

export const canWeResetPassword = computed(() => {
	const {newPassword1, newPassword2} = GlobalState.resetPassword;
	let status;
	if (newPassword1 !== "" && newPassword2 !== "") {
		status = true;
	} else {
		status = false;
	}
	return status;
});

//actions

//SignIn
export const setUsername = action(input => {
	GlobalState.login.username = input;
});

export const setPassword = action(input => {
	GlobalState.login.password = input;
});

export const setLoggingInProgress = action(status => {
	GlobalState.login.inProgress = status;
});

export const setNewPassword1 = action(input => {
	GlobalState.resetPassword.newPassword1 = input;
});

export const setNewPassword2 = action(input => {
	GlobalState.resetPassword.newPassword2 = input;
});

export const setResetInProgress = action(status => {
	GlobalState.reset.inProgress = status;
});

export const setResetError = action(status => {
	GlobalState.reset.error = status;
	GlobalState.reset.inProgress = false;
	GlobalState.reset.newPassword1 = "";
	GlobalState.reset.newPassword2 = "";
});

export const setResetErrorOnly = action(() => {
	GlobalState.reset.error = false;
});

export const setErrorMessage = action(message => {
	GlobalState.reset.errorMessage = message;
});

export const setAccount = action((user, group) => {
	GlobalState.auth.isLoggedIn = true;
	GlobalState.auth.isChecking = false;
	GlobalState.auth.username = user.username;
	GlobalState.auth.group = group;
	GlobalState.auth.isAdmin = group === "admin";
});

export const setResetPassword = action(status => {
	GlobalState.auth.isResetPassword = status;
});


export const setOffline = action((status)=>{
    GlobalState.isOffline = status;
})

export const setScreen = action((input)=>{
    GlobalState.screen = input;
})

export const setError = action((input)=>{
    GlobalState.errorObj.showError = input.showError;
		GlobalState.errorObj.errorMsg= input.errorMsg;
		GlobalState.errorObj.typeOfError= input.typeOfError;
})

export const setSuccess = action((input)=>{
		GlobalState.successObj.showSuccess = input.showSuccess;
		GlobalState.successObj.successMsg= input.successMsg;
		GlobalState.successObj.typeOfSuccess= input.typeOfSuccess;
})

export const setapplicantsForSameMobileNoshowAlert = action((input)=>{
    GlobalState.applicantsForSameMobileNoshowAlert = input;
})

export const setpatientIDCount = action((input)=>{
    GlobalState.patientIDCount = input;
})

export const setloadingModal = action((input)=>{
	GlobalState.loadingModal = input
});

export const setcenterAssignedValue = action(input => {
	let centerNo = input.substring(6,7);
	let Value = "Center".concat(" ", centerNo);
	GlobalState.centerAssignedValue = Value;
});
