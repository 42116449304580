import logo from "../../logo.jpg";
import {TextField, Button} from "@mui/material";
import * as DialysisState from "../../States/DialysisState";
import * as ReportState from "../../States/ReportState";
import {observer} from "mobx-react-lite";
import {handleNewDialysisEntryButton} from "../../Helpers/DialysisHistoryProcessing";

const NewDialysisEntry = () => {
  let Applicant = DialysisState.FormValue.newDialysisEntry;
  return (
    <div style={{background: "linear-gradient(180deg, #7f354e, #fff)",width: "100vw",height: "calc(100vh - 48px)",display: "flex",justifyContent: "center",alignItems: "center"}}>
      <div style={{background: "#fff",width: "35vw",height: "75vh",borderRadius: "8px" }}>
        <div style={{background: "#7f354e",width: "8vw",height: "8vw",borderRadius: "5vw",margin: "auto",marginTop: "-4vw",display: "flex",justifyContent: "center",alignItems: "center" }}>
          <div style={{background: "#fff",width: "7.5vw",height: "7.5vw",borderRadius: "5vw",display: "flex",justifyContent: "center",alignItems: "center" }}>
            <div style={{width: "7vw",height: "7vw",borderRadius: "50%",background: `url(${logo})`,backgroundPosition: "50% 50%",backgroundSize: "cover",backgroundRepeat: "no-repeat" }}>
            </div>
          </div>
        </div>
        <div style={{height: "calc(100% - 5vw)",display: "flex",alignItems: "center",flexDirection: "column",justifyContent:'center'}}>
          <TextField
            label="Applicant No"
            variant="outlined"
            color="primary"
            fullWidth
            disabled
            style={{width: "25vw", margin: "2vh 0vw"}}
            value={Applicant.applicantID}
          />
          <TextField
            label="Date"
            variant="outlined"
            color="primary"
            style={{width: "25vw", margin: "2vh 0vw"}}
            type="date"
            fullWidth
            onChange={event => DialysisState.setdate(event.target.value)}
            value={Applicant.date}
          />
          <TextField
            label="Amount Paid"
            variant="outlined"
            color="primary"
            fullWidth
          style={{width: "25vw", margin: "2vh 0vw"}}
            onChange={event => DialysisState.setamountPaid(event.target.value)}
            value={Applicant.amountPaid}
          />
          <TextField
            label="Remarks"
            variant="outlined"
            color="primary"
            fullWidth
            style={{width: "25vw", margin: "2vh 1vw"}}
            onChange={event => DialysisState.setremarks(event.target.value)}
            value={Applicant.remarks}
          />
          <div style={{display:"flex"}}>
          <Button
            variant="contained"
            size="large"
            style={{width: "12vw", margin: "2vh 0.5vw",borderRadius:'5px'}}
            onClick={handleNewDialysisEntryButton}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            size="large"
            style={{width: "12vw", margin: "2vh 0.5vw",borderRadius:'5px'}}
            onClick={()=>ReportState.setcomponentSetup("ApplicantHistory")}
          >
            Cancel
          </Button>
          </div>
        </div>
      </div>
    </div>
	);
};

export default observer(NewDialysisEntry);
