import * as React from 'react';
import {Alert,Dialog, DialogContent} from '@mui/material';
import {observer} from "mobx-react-lite";
import {GlobalState} from "../../States/GlobalState";

const ErrorAlert = () => {
  return (
    <Dialog onClose={()=>{}} open={true}>
      <DialogContent  style={{padding:'0px',display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center'}}>
        <Alert variant="outlined" color="primary" severity="error">{GlobalState.errorObj.errorMsg}</Alert>
      </DialogContent>
    </Dialog>
      );
}

export default observer(ErrorAlert)
