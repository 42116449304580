import { useEffect } from "react";
import logo from "../../logo.jpg";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Fab, Button } from "@mui/material";
import { Close } from '@mui/icons-material';
import * as DialysisState from "../../States/DialysisState";
import * as ReportState from "../../States/ReportState";
import { observer } from "mobx-react-lite";
import { DateConvertor } from "../../Helpers/DateConvertor";


const ApplicantHistory = () => {
  useEffect(() => {
    let elements = document.getElementsByClassName("MuiOutlinedInput-notchedOutline");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "padding-left:14px");
    }
  }, [])
  let History = DialysisState.FormValue.fetchedDataA;
  let ApplicantDetails = DialysisState.FormValue.applicantDetails;
  let issuedDate = DateConvertor(ApplicantDetails.issuedOn);
  let buttonDisabled = false;
  if (History.length >= ApplicantDetails.dialysisCount) {
    buttonDisabled = true;
  }
  const addRecordScreen = () => {
    ReportState.setcomponentSetup("NewDialysisEntry")
  }

  const closeScreen = () => {
    ReportState.setcomponentSetup("DataSearch");
    ReportState.resetsearchFields();
    ReportState.setsearchBy("patient");
  }

  return (
    <div style={{ background: "linear-gradient(180deg, #7f354e, #fff)", width: "100vw", height: "calc(100vh - 48px)", display: "flex", justifyContent: "center", alignItems: "self-end" }}>
      <div style={{ background: "#fff", width: "60vw", height: "84vh",marginBottom:'20px', borderRadius: "8px", marginTop: "40px" }}>
        <div style={{ background: "#7f354e", width: "7vw",height: "7vw",borderRadius: "5vw", margin: "auto", marginTop: "-3.5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ background: "#fff", width: "6.5vw", height: "6.5vw", borderRadius: "5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "6vw", height: "6vw", borderRadius: "50%", background: `url(${logo})`, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            </div>
          </div>
        </div>
        <div style={{ height: "calc(100% - 5vw)", display: "flex", alignItems: "center", flexDirection: "column" }}>
          <Table aria-label="simple table" style={{ borderBottom: "none", width: "94%" }}>
            <TableBody>
              <TableRow>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "10px 5px 5px" }} align="right">Patient ID: </TableCell>
                <TableCell style={{ color: "#000", borderBottom: "none", padding: "10px 5px 5px" }} align="left">{ApplicantDetails.applicantID}</TableCell>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "10px 5px 5px" }} align="right">Mobile No: </TableCell>
                <TableCell style={{ color: "#000", borderBottom: "none", padding: "10px 5px 5px" }} align="left">{ApplicantDetails.mobileNo}</TableCell>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "10px 5px 5px" }} align="right">No of Dialysis: </TableCell>
                <TableCell style={{ color: "#000", borderBottom: "none", padding: "10px 5px 5px" }} align="left">{ApplicantDetails.dialysisCount} (Monthly)</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "5px 5px 10px" }} align="right">Name: </TableCell>
                <TableCell style={{ color: "#000", borderBottom: "none", padding: "5px 5px 10px" }} align="left">{ApplicantDetails.applicantName}</TableCell>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "10px 5px 5px" }} align="right">Issued Date: </TableCell>
                <TableCell style={{ color: "#000", borderBottom: "none", padding: "10px 5px 5px" }} align="left">{issuedDate}</TableCell>
                <TableCell style={{ color: "#7f354e", borderBottom: "none", padding: "10px 5px" }} align="center" colSpan="2">
                  <Button
                    variant="outlined"
                    size="small"
                    disabled={buttonDisabled}
                    style={{ borderRadius: '5px' }}
                    onClick={addRecordScreen}
                  >
                    Add New Record
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <TableContainer component={Paper} style={{ margin: '20px', width: "calc(100% - 40px)", boxShadow: "none" }}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell style={{ color: "#fff", fontWeight: "800", background: "#7f354e", padding: "5px" }} align="center">S. No</TableCell>
                  <TableCell style={{ color: "#fff", fontWeight: "800", background: "#7f354e", padding: "5px" }} align="center">Date</TableCell>
                  <TableCell style={{ color: "#fff", fontWeight: "800", background: "#7f354e", padding: "5px" }} align="center">Amount Paid</TableCell>
                  <TableCell style={{ color: "#fff", fontWeight: "800", background: "#7f354e", padding: "5px" }} align="center">Remarks</TableCell>
                  <TableCell style={{ color: "#fff", fontWeight: "800", background: "#7f354e", padding: "5px" }} align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {History.map((row, i) => {
                  let sno = i + 1;
                  let color = '#fff';
                  if (i % 2 === 0) {
                    color = '#f6f6f6';
                  }
                  return (
                    <TableRow key={i} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="center" style={{ padding: "5px", background: color, width: "10%" }}>{sno}</TableCell>
                      <TableCell align="center" style={{ padding: "5px", background: color, width: "15%" }}>{row.date}</TableCell>
                      <TableCell align="center" style={{ padding: "5px", background: color, width: "15%" }}>₹ {row.amountPaid}</TableCell>
                      <TableCell align="center" style={{ padding: "5px", background: color }}>{row.remarks}</TableCell>
                      <TableCell align="center" style={{ padding: "5px", background: color }}></TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <Fab color="secondary" aria-label="close" style={{ marginBottom: "83vh", marginLeft: "-2vw" }} onClick={closeScreen}><Close /></Fab>
    </div>
  );
};

export default observer(ApplicantHistory);
