import * as ApplicantState from '../States/ApplicantState';
import * as EditApplicantState from '../States/EditApplicantState';

const today = new Date();
let yyyy = today.getFullYear();
let mm = today.getMonth() + 1; // Months start at 0!
let dd = today.getDate();

if (dd < 10) dd = '0' + dd;
if (mm < 10) mm = '0' + mm;
export const todayDate = yyyy + '-' + mm + '-' + dd;
export const timeStamp = today.getTime();


export const getNewIssueDate = (date1) => {
  let todayDate = new Date();
  let issueDate = new Date(date1);
  let NoOfDays = (todayDate - issueDate) / 1000 / 60 / 60 / 24;
  let NewIssueDate = issueDate;
  let NewIssueDateStatus = false;
  if (NoOfDays >= 30) {
    NewIssueDate = new Date(issueDate.setDate(issueDate.getDate() + 30));
    NewIssueDateStatus = true;
  }
  let yyyy = NewIssueDate.getFullYear();
  let mm = NewIssueDate.getMonth() + 1; // Months start at 0!
  let dd = NewIssueDate.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  let NewIssueDateProperFormat = yyyy + '-' + mm + '-' + dd;
  return { NewIssueDateProperFormat: NewIssueDateProperFormat, NewIssueDateStatus: NewIssueDateStatus };
}

export const amountWithCommas = (x) => {
  if (x !== "") {
    x = x.replace(/,/g, '');
    let res = parseInt(x).toLocaleString("en-IN");
    return res;
  } else {
    return x;
  }
}

export const convertToDateString = (date) => {
  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  if (dd < 10) dd = '0' + dd;
  if (mm < 10) mm = '0' + mm;
  let newDate = yyyy + '-' + mm + '-' + dd;
  return newDate;
}

export const handleClickImage = (event, form) => {
  if (form === "new") {
    ApplicantState.setimageInteracted(event.currentTarget);
  } else {
    EditApplicantState.setimageInteracted(event.currentTarget);
  }
}

export const handleImageMenuClose = () => {
  ApplicantState.setimageInteracted(null);
  EditApplicantState.setimageInteracted(null);
}

export const handleImagePreview = (form) => {
  if (form === "new") {
    if (ApplicantState.FormValue.imageInteracted.id) {
      let id = parseInt(ApplicantState.FormValue.imageInteracted.id.split("-")[1]);
      window.open(ApplicantState.FormValue.details.documents[id], '_blank');
    } else {
      window.open(ApplicantState.FormValue.details.applicantPhoto, '_blank');
    }
    handleImageMenuClose();
  } else {
    let resourceURL = "https://s3.ap-south-1.amazonaws.com/dialysis-jyf.iom/vault/" + EditApplicantState.FormValue.details.applicantID + "/";
    if (EditApplicantState.FormValue.imageInteracted.id) {
      let id = parseInt(EditApplicantState.FormValue.imageInteracted.id.split("-")[1]);
      window.open(resourceURL + EditApplicantState.FormValue.details.documents[id], '_blank');
    } else {
      window.open(resourceURL + EditApplicantState.FormValue.details.applicantPhoto, '_blank');
    }
    handleImageMenuClose();
  }
}

export const handleImageRemove = (form) => {
  if (form === "new") {
    if (ApplicantState.FormValue.imageInteracted.id) {
      let id = parseInt(ApplicantState.FormValue.imageInteracted.id.split("-")[1]);
      let images = [];
      ApplicantState.FormValue.details.documents.map((document, i) => {
        if (id !== i) {
          images.push(document);
        }
        return null;
      })
      ApplicantState.setdocuments(images);
    } else {
      ApplicantState.setapplicantPhoto("");
    }
    handleImageMenuClose();
  } else {
    if (EditApplicantState.FormValue.imageInteracted.id) {
      let id = parseInt(EditApplicantState.FormValue.imageInteracted.id.split("-")[1]);
      let images = [];
      EditApplicantState.FormValue.details.documents.map((document, i) => {
        if (id !== i) {
          images.push(document);
        }
        return null;
      })
      EditApplicantState.setdocuments(images);
    } else {
      EditApplicantState.setapplicantPhoto("");
    }
    handleImageMenuClose();
  }

}
