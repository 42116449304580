import * as DialysisState from "../States/DialysisState";
import * as ReportState from "../States/ReportState";
import { fetchDialysisHistoryUsingApplicantID, fetchDialysisHistoryUsingMobileNo, fetchDialysisHistoryUsingCenter, fetchApplicantUsingApplicantNameForEditApplicant, fetchApplicantUsingCenterAssignedForDownload } from "../Logics/DatabaseReads";
import { newDialysisEntry } from "../Logics/DatabaseUpdates";
import { setError, setapplicantsForSameMobileNoshowAlert, setSuccess, setloadingModal } from '../States/GlobalState';
import { handleErrorCloseButton, handleSuccessCloseButton } from "./Alert";
import * as jsonexport from "jsonexport/dist";
import { convertToDateString } from "./General";

export const handleApplicantSearchButtonClicked = async (searchBy, value) => {
  DialysisState.setfetchedDataA([]);
  if (searchBy === "applicantID") {
    let res = await fetchDialysisHistoryUsingApplicantID(value);
    if (!res) {
      setError({ showError: true, errorMsg: "Patient ID not found! Please verify and try again.", typeOfError: "ApplicantIDNotFoundInDB" });
      setTimeout(handleErrorCloseButton, 2000);
    }
  }
  if (searchBy === "mobileNo") {
    let res = await fetchDialysisHistoryUsingMobileNo(value);
    if (!res) {
      setError({ showError: true, errorMsg: "Mobile no not found! Please verify and try again.", typeOfError: "MobileNoNotFoundInDB" });
      setTimeout(handleErrorCloseButton, 2000);
    }
  }
  if (searchBy === "applicantName") {
    let res = await fetchDialysisHistoryUsingApplicantID(value);
    if (!res) {
      setError({ showError: true, errorMsg: "Patient not Found! Please verify and try again.", typeOfError: "MobileNoNotFoundInDB" });
      setTimeout(handleErrorCloseButton, 2000);
    }
  }
}

export const handleAddNewButtonClicked = () => {
  DialysisState.setcomponentSetup("NewDialysisEntry");
};

export const handleSubmitNewEntryButtonClicked = () => {
  DialysisState.setcomponentSetup("NewDialysisEntry");
};

export const handlePatientClickedForSameMobileNo = async (applicantID) => {
  DialysisState.setapplicantID(applicantID);
  await handleApplicantSearchButtonClicked("applicantID", applicantID);
  DialysisState.setapplicantsForSameMobileNo({ showAlert: false, itemList: [] });
  setapplicantsForSameMobileNoshowAlert(false);
}

const formFields = (DialysisData) => {
  return {
    "dialysisID": DialysisData.dialysisID,
    "applicantID": DialysisData.applicantID,
    "applicantName": DialysisData.applicantName,
    "date": DialysisData.date,
    "amountPaid": DialysisData.amountPaid,
    "remarks": DialysisData.remarks,
    "centerAssigned": DialysisData.centerAssigned,
  }
}

export const handleNewDialysisEntryButton = async () => {
  let DialysisData = DialysisState.FormValue.newDialysisEntry;
  let FormDataNewEntry = formFields(DialysisData);
  try {
    let resArray = await newDialysisEntry(FormDataNewEntry);
    if (resArray) {
      setSuccess({ showSuccess: true, successMsg: "Adding Record to Database...", typeOfSuccess: "DialysisSuccessfullyAdded" })
      let newArray = DialysisState.FormValue.fetchedDataA;
      newArray.push(FormDataNewEntry);
      DialysisState.setfetchedDataA(newArray);
      ReportState.setcomponentSetup("ApplicantHistory")
      DialysisState.clearFields();
      setTimeout(handleSuccessCloseButton, 1000);
    }
  } catch (e) {
    console.log(e);
    handleSuccessCloseButton();
    setError({ showError: true, errorMsg: e, typeOfError: "DialysisUnsuccessful" });
    setTimeout(handleErrorCloseButton, 2000);
  }
}

export const createDownloadableCSV = (data, name, count) => {
  jsonexport(data, (err, csv) => {
    if (err) return console.error(err);
    let csvAsBlob = new Blob([csv], {
      type: 'text/csv'
    });
    downloadBlob(csvAsBlob, name, count);
    // setLoadingModal(false);
  });
}

export const downloadBlob = (blob, name, length) => {
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = name;
  document.body.appendChild(link);
  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window
    })
  );
  document.body.removeChild(link);
  setSuccess({ showSuccess: true, successMsg: length + " Records Downloaded", typeOfSuccess: "" })
  setloadingModal(false);
  setTimeout(handleSuccessCloseButton, 1000);
}

export const pushData = (data, count, amount, items) => {
  if (items.length > 0) {
    items.map((item) => {
      item = { Date: item.date, Center: item.centerAssigned, PatientID: item.applicantID, Name: item.applicantName, Amount: item.amountPaid, Remarks: item.remark };
      data.push(item)
      count = count + 1;
      amount = amount + Number(item.Amount);
      return 0;
    })
  }
  return { data, count, amount };
}

export const processAndPushData = (data, items) => {
  if (items.length > 0) {
    items.map((item) => {
      let index = data.findIndex(eachEntry => eachEntry.PatientID === item.applicantID);
      if (index > -1) {
        if (item.amountPaid > 0) {
          data[index].Amount = Number(data[index].Amount) + Number(item.amountPaid);
        }
        data[index].DialysisCount = data[index].DialysisCount + 1;
      }
      else {
        item = { PatientID: item.applicantID, Name: item.applicantName, Amount: item.amountPaid, DialysisCount: 1 };
        data.push(item)
      }
      return 0;
    })
  }
  return { data };
}

export const pushApplicantRecords = (items) => {
  let data = [];
  if (items.length > 0) {
    items.map((item) => {
      item = { Date: item.date, PatientID: item.applicantID, Name: item.applicantName, MobileNo: item.mobileNo, Remarks: item.remark };
      data.push(item);
      return 0;
    })
  }
  return data;
}

export const downloadAllSelectedData = async () => {
  let token = null;
  let { inputDetails } = ReportState.FormValue;
  if (inputDetails.centerAssigned === "Center") {
    let outputData = { data: [], count: 0, amount: 0 };
    let output2Data = { data: [], count: 0, amount: 0 };
    let result = [];
    let date1 = convertToDateString(new Date(getTimeValue(inputDetails.date)));
    let date2 = convertToDateString(new Date(getTimeValue(inputDetails.dateEnd)));
    do {
      result = await fetchDialysisHistoryUsingCenter(inputDetails.centerAssigned, token, date1, date2);
      token = result.nextToken;
      outputData = pushData(outputData.data, outputData.count, outputData.amount, result.items);
      output2Data = processAndPushData(output2Data.data, result.items);
    } while (token);
    outputData.data = [...outputData.data, {}, { Date: `Total Dialysis Count:`, Center: outputData.count, Name: `Total Amount:`, Amount: outputData.amount }];
    output2Data.data = [...output2Data.data, {}, { PatientID: `Total Dialysis Count:`, Name: outputData.count, Amount: `Total Amount:`, DialysisCount: outputData.amount }];
    return { fullData: outputData.data, combinedData: output2Data.data, count: outputData.count };
  } else {
    let date1 = convertToDateString(new Date(getTimeValue(inputDetails.date)));
    let date2 = convertToDateString(new Date(getTimeValue(inputDetails.dateEnd)));
    let result = await fetchApplicantUsingCenterAssignedForDownload(inputDetails.centerAssigned, date1, date2);
    let outputData = pushApplicantRecords(result);
    return { fullData: outputData, combinedData: null, count: outputData.length };
  }
}

export const getTimeValue = (date) => {
  let time = new Date(date).getTime();
  return time;
}

export const downloadData = async () => {
  setloadingModal(true);
  let data = await downloadAllSelectedData();
  if (data.fullData.length > 0) {
    createDownloadableCSV(data.fullData, `${ReportState.FormValue.inputDetails.centerAssigned}-${ReportState.FormValue.inputDetails.date}-${ReportState.FormValue.inputDetails.dateEnd}`, data.count);
    if (data.combinedData) {
      createDownloadableCSV(data.combinedData, `combined-History-${ReportState.FormValue.inputDetails.date}-${ReportState.FormValue.inputDetails.dateEnd}`, data.count);
    }
  } else {
    setError({ showError: true, errorMsg: "No Records found", typeOfError: "RecordEmpty" });
    setloadingModal(false);
    setTimeout(handleErrorCloseButton, 1000);
  }
}

export const handleApplicantSearchByName = async () => {
  let ApplicantName = DialysisState.FormValue.nameSearch.nameData;
  let fetchedData = await fetchApplicantUsingApplicantNameForEditApplicant(ApplicantName);
  if (fetchedData) {
    DialysisState.setoptionData(fetchedData);
    let newFetchData = [];
    fetchedData.map((data) => {
      let a = { label: data.applicantName, applicantID: data.applicantID };
      newFetchData.push(a);
      return newFetchData;
    });
    DialysisState.setnameOption(newFetchData);
  }
}

export const handleNameSelectedFromDropDown = async (value) => {
  DialysisState.setnameSelected(value);
  if (!value) {
    DialysisState.setoptionData([]);
    DialysisState.setnameOption([]);
  } else {
    let id = value.applicantID;
    let applicantData = DialysisState.FormValue.nameSearch.optionData.find((data) => { if (data.applicantID === id) { return data } else { return {} } })
    DialysisState.setapplicantDetails(applicantData);
    ReportState.setpatientDetails(id);
  }
}