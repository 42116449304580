import {observable, action, computed} from "mobx";

import {todayDate, amountWithCommas} from "../Helpers/General";

export const FormValue = observable({
	details: {
    applicantID: "",
    date: todayDate,
    applicantName: "",
    age: "",
    address : "",
    occupation : "",
    mobileNo : "",
    landlineNo : "",
    nativePlace : "",
    familyMembersCount : "",
    monthlyIncomeFamily : "",
    caste : "",
    doctor: "",
    dialysisStartDate: "",
    memberRef: "",
    discussedOn: "",
    issuedOn: "",
    passedBy: "",
    rejected: "",
    remarks : "",
    applicantPhoto : "",
    centerAssigned : "",
    rationCardColor : "",
    documents : [],
    rateApproved:"",
    dialysisCount:"",
		documentsName:[],
		applicantPhotoName:"",
  },
	imageInteracted:null,
});

//
	export const requiredFields = computed(()=>{
		let {applicantID, applicantName, centerAssigned, mobileNo} = FormValue.details;
		let applicantIDError = false;
		let applicantNameError = false;
		let centerAssignedError = false;
		let mobileNoError = false;
		let disableSubmitButtom = false;
		if(applicantID===""){
			applicantIDError = true;
		}
		if(applicantName===""){
			applicantNameError = true;
		}
		if(centerAssigned===""){
			centerAssignedError = true;
		}
		if(mobileNo===""){
			mobileNoError = true;
		}
		if(applicantID==="" || applicantName==="" || mobileNo==="" || centerAssigned===""){
			disableSubmitButtom = true;
		}

		return {applicantIDError,applicantNameError,centerAssignedError,mobileNoError,disableSubmitButtom}
	})
  //actions
  export const setdate = action(input => {
    FormValue.details.date = input;
  });

  export const setapplicantID = action(input => {
  	FormValue.details.applicantID = input;
  });

  export const setapplicantName = action(input => {
  	FormValue.details.applicantName = input.toUpperCase();
  });

  export const setage = action(input => {
    FormValue.details.age = input;
  });

  export const setaddress = action(input => {
    FormValue.details.address = input;
  });

  export const setoccupation = action(input => {
    FormValue.details.occupation = input;
  });

  export const setmobileNo = action(input => {
    FormValue.details.mobileNo = input;
  });

  export const setlandlineNo = action(input => {
    FormValue.details.landlineNo = input;
  });

  export const setnativePlace = action(input => {
    FormValue.details.nativePlace = input;
  });

  export const setfamilyMembersCount = action(input => {
    FormValue.details.familyMembersCount = input;
  });

  export const setmonthlyIncomeFamily = action(input => {
    let newInput = amountWithCommas(input);
    FormValue.details.monthlyIncomeFamily = newInput;
  });

  export const setcaste = action(input => {
    FormValue.details.caste = input;
  });

  export const setdoctor = action(input => {
    FormValue.details.doctor = input;
  });

  export const setdialysisStartDate = action(input => {
    FormValue.details.dialysisStartDate = input;
  });

  export const setmemberRef = action(input => {
    FormValue.details.memberRef = input;
  });

  export const setdiscussedOn = action(input => {
    FormValue.details.discussedOn = input;
  });

  export const setissuedOn = action(input => {
    FormValue.details.issuedOn = input;
  });

  export const setpassedBy = action(input => {
    FormValue.details.passedBy = input;
  });

  export const setrejected = action(input => {
    FormValue.details.rejected = input;
  });

  export const setremarks = action(input => {
    FormValue.details.remarks = input;
  });

  export const setapplicantPhoto = action(input => {
    FormValue.details.applicantPhoto = input;
  });

  export const setapplicantSign = action(input => {
    FormValue.details.applicantSign = input;
  });

  export const setcenterAssigned = action(input => {
    FormValue.details.centerAssigned = input;
  });

  export const setrationCardColor = action(input => {
    FormValue.details.rationCardColor = input;
  });

  export const setdocuments = action(input => {
    FormValue.details.documents = input;
  });

  export const setdialysisCount = action(input => {
    FormValue.details.dialysisCount = input;
  });

  export const setrateApproved = action(input => {
    FormValue.details.rateApproved = input;
  });

	export const setimageInteracted = action(input => {
    FormValue.imageInteracted = input;
  });

	export const setdocumentsName = action(input => {
    FormValue.details.documentsName = input;
  });

	export const setapplicantPhotoName = action(input => {
		FormValue.details.applicantPhotoName = input;
	});

	export const clearFields = action(()=>{
		if(FormValue.details.applicantPhoto!==""){
			URL.revokeObjectURL(FormValue.details.applicantPhoto)
		}
		if(FormValue.details.documents.length>0){
			FormValue.details.documents.forEach((url)=>{
				URL.revokeObjectURL(url);
			})
		}
		FormValue.details = {...FormValue.details,
	    date: todayDate,
	    applicantName: "",
	    age: "",
	    address : "",
	    occupation : "",
	    mobileNo : "",
	    landlineNo : "",
	    nativePlace : "",
	    familyMembersCount : "",
	    monthlyIncomeFamily : "",
	    caste : "",
	    doctor: "",
	    dialysisStartDate: "",
	    memberRef: "",
	    discussedOn: "",
	    issuedOn: "",
	    passedBy: "",
	    rejected: "",
	    remarks : "",
	    applicantPhoto : "",
	    centerAssigned : "",
	    rationCardColor : "",
	    documents : [],
	    rateApproved:"",
	    dialysisCount:"",
			documentsName:[],
			applicantPhotoName:"",
		}
	})
