export const DateConvertor = (date) => {
    if(date !== ""){
        let dateSplit = date.split("-");
        let newDate = dateSplit[2]+"-"+dateSplit[1]+"-"+dateSplit[0];
        return newDate;
    }
}

export const DateConvertorFromOriginal = (date) => {
    if(date !== ""){
        let year = date.getFullYear();
        let month = date.getMonth()+1;
        let day = date.getDate();
        let newDate = year+"-"+month+"-"+day;
        return newDate;
    }
}