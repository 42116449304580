import { observer } from "mobx-react-lite";
import { Button, Box, AppBar, Link } from "@mui/material";
import { GlobalState } from "../States/GlobalState";
import LoadingModal from "../Components/LoadingModal";
import { signingOut } from "../Helpers/SignInSignUp";
import ErrorAlert from "../Components/HelperComp/ErrorAlert";
import SuccessAlert from "../Components/HelperComp/SuccessAlert";
import ReportsN from "../Components/ReportsN";

const App = () => {
	let { showError } = GlobalState.errorObj;
	let { showSuccess } = GlobalState.successObj;
	return (
		<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
			<AppBar position="static" style={{ width: "100%", height: '48px' }} />
			<ReportsN />
			<Button 
				style={{ position: "absolute", color: "white", top: 0, right: 0, padding: "12px 20px" }}
				onClick={signingOut}>
				Logout
			</Button>
			<LoadingModal />
			{showError &&
				<ErrorAlert />
			}
			{showSuccess &&
				<SuccessAlert />
			}
		<Link underline="hover" style={{position:'absolute',bottom:'10px',right:'10px',color:'#7f354e',fontWeight:'500',fontStyle:'italic'}} href="https://wa.me/919979441109?text=Hello%21%0AI%20need%20more%20information%20about%20your%20company%2E" target="_blank">Created By DesignoTech</Link>
		</Box>

	)
};

export default observer(App);
