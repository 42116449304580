/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getApplicantDB = /* GraphQL */ `
  query GetApplicantDB($applicantID: String!) {
    getApplicantDB(applicantID: $applicantID) {
      applicantID
      date
      applicantName
      age
      address
      occupation
      mobileNo
      landlineNo
      nativePlace
      familyMembersCount
      monthlyIncomeFamily
      caste
      doctor
      dialysisStartDate
      memberRef
      discussedOn
      issuedOn
      passedBy
      rejected
      remarks
      applicantPhoto
      centerAssigned
      rationCardColor
      documents
      rateApproved
      dialysisCount
      createdAt
      updatedAt
    }
  }
`;
export const listApplicantDBS = /* GraphQL */ `
  query ListApplicantDBS(
    $applicantID: String
    $filter: ModelApplicantDBFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listApplicantDBS(
      applicantID: $applicantID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        applicantID
        date
        applicantName
        age
        address
        occupation
        mobileNo
        landlineNo
        nativePlace
        familyMembersCount
        monthlyIncomeFamily
        caste
        doctor
        dialysisStartDate
        memberRef
        discussedOn
        issuedOn
        passedBy
        rejected
        remarks
        applicantPhoto
        centerAssigned
        rationCardColor
        documents
        rateApproved
        dialysisCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDialysisDB = /* GraphQL */ `
  query GetDialysisDB($dialysisID: String!) {
    getDialysisDB(dialysisID: $dialysisID) {
      dialysisID
      applicantID
      applicantName
      date
      amountPaid
      remarks
      centerAssigned
      createdAt
      updatedAt
    }
  }
`;
export const listDialysisDBS = /* GraphQL */ `
  query ListDialysisDBS(
    $dialysisID: String
    $filter: ModelDialysisDBFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDialysisDBS(
      dialysisID: $dialysisID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dialysisID
        applicantID
        applicantName
        date
        amountPaid
        remarks
        centerAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const applicantsByCenterAssignedAndDate = /* GraphQL */ `
  query ApplicantsByCenterAssignedAndDate(
    $centerAssigned: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelapplicantDBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    applicantsByCenterAssignedAndDate(
      centerAssigned: $centerAssigned
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        applicantID
        date
        applicantName
        age
        address
        occupation
        mobileNo
        landlineNo
        nativePlace
        familyMembersCount
        monthlyIncomeFamily
        caste
        doctor
        dialysisStartDate
        memberRef
        discussedOn
        issuedOn
        passedBy
        rejected
        remarks
        applicantPhoto
        centerAssigned
        rationCardColor
        documents
        rateApproved
        dialysisCount
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dialysisByApplicantID = /* GraphQL */ `
  query DialysisByApplicantID(
    $applicantID: String!
    $sortDirection: ModelSortDirection
    $filter: ModeldialysisDBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dialysisByApplicantID(
      applicantID: $applicantID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dialysisID
        applicantID
        applicantName
        date
        amountPaid
        remarks
        centerAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dialysisByDate = /* GraphQL */ `
  query DialysisByDate(
    $date: String!
    $sortDirection: ModelSortDirection
    $filter: ModeldialysisDBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dialysisByDate(
      date: $date
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dialysisID
        applicantID
        applicantName
        date
        amountPaid
        remarks
        centerAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const dialysisByCenterAssignedAndDate = /* GraphQL */ `
  query DialysisByCenterAssignedAndDate(
    $centerAssigned: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeldialysisDBFilterInput
    $limit: Int
    $nextToken: String
  ) {
    dialysisByCenterAssignedAndDate(
      centerAssigned: $centerAssigned
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dialysisID
        applicantID
        applicantName
        date
        amountPaid
        remarks
        centerAssigned
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
