import {useState} from "react";
import {observer} from "mobx-react-lite";
import {Box,Tab,Tabs,AppBar, Button, Link} from "@mui/material";
import {GlobalState} from "../States/GlobalState";
import ApplicantForm from "../Components/ApplicantForm";
import EditApplicantForm from "../Components/EditApplicantForm";
import ReportsN from "../Components/ReportsN";
import LoadingModal from "../Components/LoadingModal";
import ErrorAlert from "../Components/HelperComp/ErrorAlert";
import SuccessAlert from "../Components/HelperComp/SuccessAlert";
import {signingOut}  from "../Helpers/SignInSignUp";

const AdminPage = () => {
	const [value, setValue] = useState(0);
	const handleChange = (event,newValue) => {
		setValue(newValue);
  };
	let {showError} = GlobalState.errorObj;
	let {showSuccess} = GlobalState.successObj;
	return (
		<>
		<Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
		   <AppBar position="static" style={{alignItems:"center"}}>
				<Tabs
					value={value}
					onChange={handleChange}
					indicatorColor="secondary"
					textColor="inherit"
					variant="fullWidth"
					style={{width:"60vw"}}
				>
					<Tab label="New Applicant" />
					<Tab label="Edit Applicant"/>
					<Tab label="Reports"/>
	          	</Tabs>
				</AppBar>
				<Button style={{position:"absolute",color:"white",top:0,right:0,padding:"12px 20px"}}
								onClick={signingOut}>
						Logout
				</Button>
					{value===0 && <ApplicantForm />}
					{value===1 && <EditApplicantForm />}
					{value===2 && <ReportsN />}
				<LoadingModal/>
				{showError &&
					<ErrorAlert />
				}
				{showSuccess &&
					<SuccessAlert />
				}
				<Link underline="hover" style={{position:'absolute',bottom:'10px',right:'10px',color:'#7f354e',fontWeight:'500',fontStyle:'italic'}} href="https://wa.me/919979441109?text=Hello%21%0AI%20need%20more%20information%20about%20your%20company%2E" target="_blank">Created By DesignoTech</Link>
	  </Box>
		</>

	)
};

export default observer(AdminPage);
