
import {TextField, Autocomplete} from "@mui/material";
import {observer} from "mobx-react-lite";
import {handleApplicantSearchByName, handleNameSelectedFromDropDown} from "../../Helpers/EditApplicantDataProcessing";
import * as EditApplicantState from "../../States/EditApplicantState";

const fetchNameDataCalled = (nameData) => {
    let newName = nameData.toUpperCase();
    EditApplicantState.setnameData(newName);
    if(nameData.length === 0){
        EditApplicantState.clearFields();
    }else if(nameData.length === 3){
        EditApplicantState.setnameData(newName);
        handleApplicantSearchByName();
    }else if(nameData.length < 3){
        EditApplicantState.setoptionData([]);
        EditApplicantState.setnameOption([]);
    }
}

const ApplicantNameSearch = () => {
    return (
        <Autocomplete
            id="InputputnameAutocomp"
            style={{width:'100%'}}
            options= {EditApplicantState.FormValue.nameSearch.nameOption}
            autoSelect
            openOnFocus
            value={EditApplicantState.FormValue.nameSearch.nameSelected}
            renderInput={(params) => 
                <TextField {...params} 
                    id="inputNameTextfield" 
                    label="Name" 
                    variant="standard"
                    onChange={(e)=> fetchNameDataCalled(e.target.value)} 
                />}
            onChange={(event,newValue)=>{handleNameSelectedFromDropDown(newValue)}}   
        />
	)
};

export default observer(ApplicantNameSearch);