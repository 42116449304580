import { useEffect } from "react";
import { observer } from "mobx-react-lite";
import SignInSignUpPage from "./Pages/01_SignInSignUpPage.js";
import AdminPage from "./Pages/02_AdminPage.js";
import CenterPage from "./Pages/03_CenterPage.js";
import { checkUserIsLoggedIn } from "./Helpers/SignInSignUp";
import { GlobalState } from "./States/GlobalState";
import "./App.css"


const App = () => {
	useEffect(() => {
		checkUserIsLoggedIn().then()
	}, []);

	if (GlobalState.screen === "SignInSignUpPage") {
		return <SignInSignUpPage />
	}
	else if (GlobalState.screen === "AdminPage") {
		return <AdminPage />;
	}
	else if (GlobalState.screen === "CenterPage") {
		return <CenterPage />;
	}
};

export default observer(App);
