import {observable, action, computed} from "mobx";
import * as ApplicantState from "./ApplicantState";
import * as EditApplicantState from "./EditApplicantState";
import {getFileNameFromImageURL} from "../Logics/DatabaseUpdates";

export const UploadState = observable({
    newContentCount:0,
    newContentProcessed:0,
    businessCardError:false,
    importantFileError:false,
    loadingModal:false,
    adding:''
});

//computed

export const allContentProcessed = computed(()=>{
    const newContent = UploadState.newContentCount;
    const contentProcessed = UploadState.newContentProcessed;
    if (newContent===contentProcessed){
        setNewContentCount(0);
        setNewContentProcessed(0);
        return true;
    }else {
        return false;
    }
});


//actions


export const setNewContentCount = action((count) => {
    UploadState.newContentCount = count;
});

export const setNewContentProcessed = action((count) => {
    UploadState.newContentProcessed = count;
});

export const setNewContentTwoKURL = action((url)=>{
    if(UploadState.adding==='photo'){
        ApplicantState.FormValue.details.applicantPhoto=url;
        let name = getFileNameFromImageURL(url);
        ApplicantState.FormValue.details.applicantPhotoName = name;
    }else if(UploadState.adding==='documents'){
        ApplicantState.FormValue.details.documents.push(url);
        ApplicantState.FormValue.details.documentsName.push(getFileNameFromImageURL(url));
    }
});

export const setEditContentTwoKURL = action((url)=>{

    if(UploadState.adding==='photo'){
        EditApplicantState.FormValue.details.applicantPhoto=url;
        let name = getFileNameFromImageURL(url);
        EditApplicantState.FormValue.details.applicantPhotoName = name;
    }else if(UploadState.adding==='documents'){
        EditApplicantState.FormValue.details.documents.push(url);
        EditApplicantState.FormValue.details.documentsName.push(getFileNameFromImageURL(url));
    }
});

export const setBusinessCardError = action((input) => {
    UploadState.businessCardError = input;
});

export const setImportantFileError = action((input) => {
    UploadState.importantFileError = input;
});

export const setAddingImage = action((input) => {
    UploadState.adding = input;
});


export const setLoadingModal = action((input) => {
    UploadState.loadingModal = input;
});
