import * as React from 'react';
import { Alert, Dialog, DialogContent } from '@mui/material';
import { GlobalState } from "../../States/GlobalState";
import { observer } from "mobx-react-lite";

const SuccessAlert = () => {
  return (
    <Dialog onClose={() => { }} open={true}>
      <DialogContent style={{ padding: '0px', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
        <Alert variant="outlined" severity="success">{GlobalState.successObj.successMsg}</Alert>
      </DialogContent>
    </Dialog>

  );
}

export default observer(SuccessAlert);
