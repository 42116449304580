import { observer } from "mobx-react-lite";
import logo from "../../logo.jpg";
import { TextField, Button, MenuItem } from "@mui/material";
import * as ReportState from "../../States/ReportState";
import SearchByCenter from "./SearchByCenter";
import SearchByPatient from "./SearchByPatient";
import { GlobalState } from "../../States/GlobalState";

const DataSearch = () => {
  let { setsearchBy, resetsearchFields } = ReportState;
  let { searchBy } = ReportState.FormValue;

  return (
    <div style={{ background: "linear-gradient(180deg, #7f354e, #fff)", width: "100vw", height: "calc(100vh - 48px)", display: "flex", justifyContent: "center", alignItems: "self-end" }}>
      <div style={{ background: "#fff", width: "35vw", height: "84vh",marginBottom:'20px', borderRadius: "8px" }}>
        <div style={{ background: "#7f354e", width: "7vw", height: "7vw", borderRadius: "5vw", margin: "auto", marginTop: "-3.5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ background: "#fff", width: "6.5vw", height: "6.5vw", borderRadius: "5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <div style={{ width: "6vw", height: "6vw", borderRadius: "50%", background: `url(${logo})`, backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
            </div>
          </div>
        </div>
        <div style={{ height: "calc(100% - 5vw)", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
          <TextField
            select
            label="Search By"
            variant="outlined"
            color="primary"
            style={{ width: "25vw", margin: "-2vh 0 2vh 0vw" }}
            onChange={e => setsearchBy(e.target.value)}
            value={searchBy}
          >
            {GlobalState.screen === "AdminPage" && <MenuItem value={"center"}>Center</MenuItem>}
            <MenuItem value={"patient"}>Patient</MenuItem>
          </TextField>
          {searchBy === "center" && <SearchByCenter />}
          {searchBy === "patient" && <SearchByPatient />}
          {searchBy === "" ?
            <Button
              variant="contained"
              size="large"
              style={{ width: "25vw", margin: "2vh 0vw 1vh" }}
              // onClick={handleSearchButtonClicked}
              disabled={true}
            >
              Search
            </Button>
            :
            <Button
              variant="outlined"
              size="large"
              style={{ width: "25vw", margin: "1vh 0vw 2vh" }}
              onClick={resetsearchFields}
            >
              Clear
            </Button>
          }
        </div>
      </div>
    </div>
  );
};

export default observer(DataSearch);
