import {TextField, Button, MenuItem} from "@mui/material";
import * as ReportState from "../../States/ReportState";
import {CenterList} from "../../Helpers/Dataset";
import {downloadData} from "../../Helpers/DialysisHistoryProcessing";
import {todayDate} from "../../Helpers/General";
import {observer} from "mobx-react-lite";

const SearchByCenter = () => {
  let {setdate, setcenterAssigned, searchButtonDisabled, setdateEnd} = ReportState;
  let {inputDetails} = ReportState.FormValue;

  return (
      <>
          <TextField
            select
            label="Select Center"
            variant="outlined"
            color="primary"
            style={{width: "25vw", margin: "2vh 0vw"}}
            onChange={(e) => setcenterAssigned(e.target.value)}
            value={inputDetails.centerAssigned}
          >
            {CenterList.map(center=>{
              return <MenuItem value={center} key={center}>{center}</MenuItem>
            })}
          </TextField>
          <TextField
            InputProps={{inputProps: {max: todayDate}}}
            label="From Date"
            type="date"
            variant="outlined"
            color="primary"
            style={{width: "25vw", margin: "2vh 0vw"}}
            onChange={e => setdate(e.target.value)}
            value={inputDetails.date}
          />
          <TextField
            InputProps={{inputProps: {min:inputDetails.date, max: todayDate}}}
            label="To Date"
            type="date"
            variant="outlined"
            color="primary"
            style={{width: "25vw", margin: "2vh 0vw"}}
            onChange={e => setdateEnd(e.target.value)}
            value={inputDetails.dateEnd}
          />
          <Button
            variant="contained"
            size="large"
            style={{width: "25vw", margin: "2vh 0vw 1vh"}}
            onClick={()=>
              {
                downloadData();
              }
            }
            disabled={searchButtonDisabled.get()}
          >
            Download CSV
          </Button>
        </>
	);
};

export default observer(SearchByCenter);
