import { useEffect } from "react";
import logo from "../logo.jpg";
import { TextField, Button, InputAdornment, Grid, Menu, MenuItem } from "@mui/material";
import { UploadFile, AddAPhoto } from '@mui/icons-material';
import * as ApplicantState from "../States/ApplicantState";
import { observer } from "mobx-react-lite";
import { handlePhotoUpload, handleDocumentsUpload } from '../Helpers/FileUploads';
import { CenterList } from "../Helpers/Dataset";
import { handleImageMenuClose, handleClickImage, handleImageRemove, handleImagePreview } from '../Helpers/General';
import { handleSubmitNewApplicant, handleFetchPatientIDCount } from "../Helpers/ApplicantDataProcessing";

const ApplicantFormPage = () => {

  useEffect(() => {
    let elements = document.getElementsByClassName("MuiOutlinedInput-notchedOutline");
    for (let i = 0; i < elements.length; i++) {
      elements[i].setAttribute("style", "padding-left:14px");
    }
    handleFetchPatientIDCount();

  }, [])

  let Applicant = ApplicantState.FormValue.details;
  let requiredFields = ApplicantState.requiredFields;

  return (
    <div style={{ background: "linear-gradient(180deg, #7f354e, #fff)", width: "100vw", height: "calc(100vh - 48px)", display: "flex", justifyContent: "center", alignItems: "self-end" }}>
      <div style={{ background: "#fff", width: "90vw", height: "84vh", marginBottom: '20px', borderRadius: "8px" }}>
        <div style={{ background: "#7f354e", width: "7vw", height: "7vw", borderRadius: "5vw", margin: "auto", marginTop: "-3.5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ background: "#fff", width: "6.5vw", height: "6.5vw", borderRadius: "5vw", display: "flex", justifyContent: "center", alignItems: "center" }}>
            {Applicant.applicantPhoto !== "" ?
              <>
                <img src={Applicant.applicantPhoto} style={{ width: "6vw", height: "6vw", borderRadius: "50%", backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat", cursor: 'pointer' }} onClick={(event) => { handleClickImage(event, "new") }} alt="ImageLogo" />
                <Menu
                  key={"menu"}
                  anchorEl={ApplicantState.FormValue.imageInteracted}
                  open={Boolean(ApplicantState.FormValue.imageInteracted)}
                  onClose={handleImageMenuClose}
                >
                  <MenuItem key={"preview"} onClick={() => handleImagePreview("new")}>Preview</MenuItem>
                  <MenuItem key={"delete"} onClick={() => handleImageRemove("new")}>Delete</MenuItem>
                </Menu>
              </>
              :
              <img src={logo} style={{ width: "6vw", height: "6vw", borderRadius: "50%", backgroundPosition: "50% 50%", backgroundSize: "cover", backgroundRepeat: "no-repeat" }} alt="ImageLogo" />
            }
          </div>
        </div>
        <div style={{ margin: "0vh 2vh" }}>
          <Grid container>
            <Grid item xs={2} style={{ padding: "2vh 1vw 1vh" }}>
              <TextField
                label="Patient ID"
                variant="outlined"
                color="primary"
                fullWidth
                required
                disabled
                error={requiredFields.get().applicantIDError}
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                value={Applicant.applicantID}
              />
            </Grid>
            <Grid item xs={6} style={{ padding: "2vh 1vw 1vh" }}>
              <TextField
                label="Name"
                variant="outlined"
                color="primary"
                fullWidth
                required
                error={requiredFields.get().applicantNameError}
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                onChange={event => ApplicantState.setapplicantName(event.target.value)}
                value={Applicant.applicantName}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={4} style={{ padding: "2vh 1vw 1vh" }}>
                  <TextField
                    label="Age"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setage(event.target.value)}
                    value={Applicant.age}
                    type="number"
                  />
                </Grid>
                <Grid item xs={8} style={{ padding: "2vh 1vw 1vh" }}>
                  <TextField
                    label="Date"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ shrink: true, style: { paddingLeft: "12px" } }}
                    type="date"
                    fullWidth
                    disabled
                    onChange={event => ApplicantState.setdate(event.target.value)}
                    value={Applicant.date}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={5} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Address"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '12px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                multiline
                rows={4}
                onChange={event => ApplicantState.setaddress(event.target.value)}
                value={Applicant.address}
              />
            </Grid>
            <Grid item xs={7}>
              <Grid container>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Occupation"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setoccupation(event.target.value)}
                    value={Applicant.occupation}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Landline No"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setlandlineNo(event.target.value)}
                    value={Applicant.landlineNo}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Mobile No"
                    variant="outlined"
                    color="primary"
                    required
                    error={requiredFields.get().mobileNoError}
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px', maxLength: 10 } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setmobileNo(event.target.value)}
                    value={Applicant.mobileNo}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Original Native Place"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setnativePlace(event.target.value)}
                    value={Applicant.nativePlace}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="No of Family Members"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setfamilyMembersCount(event.target.value)}
                    value={Applicant.familyMembersCount}
                  />
                </Grid>
                <Grid item xs={4} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Total Family Income (Monthly)"
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      style: { borderRadius: '30px' }
                    }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setmonthlyIncomeFamily(event.target.value)}
                    value={Applicant.monthlyIncomeFamily}
                  />
                </Grid>

              </Grid>
            </Grid>
            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Caste"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setcaste(event.target.value)}
                value={Applicant.caste}
              />
            </Grid>


            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Name of Doctor"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setdoctor(event.target.value)}
                value={Applicant.doctor}
              />
            </Grid>

            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="From When Dialysis Started"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ shrink: true, style: { paddingLeft: "12px" } }}
                type="date"
                fullWidth
                onChange={event => ApplicantState.setdialysisStartDate(event.target.value)}
                value={Applicant.dialysisStartDate}
              />
            </Grid>
            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="JYF Member (ref)"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setmemberRef(event.target.value)}
                value={Applicant.memberRef}
              />
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={6} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Discussed On"
                    variant="outlined"
                    color="primary"
                    InputProps={{ style: { borderRadius: '30px' } }}
                    inputProps={{ style: { paddingLeft: '24px' } }}
                    InputLabelProps={{shrink: true,  style: { paddingLeft: "12px" } }}
                    fullWidth
                    type="date"
                    onChange={event => ApplicantState.setdiscussedOn(event.target.value)}
                    value={Applicant.discussedOn}
                  />
                </Grid>
                <Grid item xs={6} style={{ padding: "1vh 1vw" }}>
                  <TextField
                    label="Rate (Approved)"
                    variant="outlined"
                    color="primary"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">₹</InputAdornment>,
                      style: { borderRadius: '30px' }
                    }}
                    InputLabelProps={{ style: { paddingLeft: "12px" } }}
                    fullWidth
                    onChange={event => ApplicantState.setrateApproved(event.target.value)}
                    value={Applicant.rateApproved}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Passed By"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setpassedBy(event.target.value)}
                value={Applicant.passedBy}
              />
            </Grid>

            <Grid item xs={3} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="No of Dialysis (Monthly)"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setdialysisCount(event.target.value)}
                value={Applicant.dialysisCount}
              />
            </Grid>
            <Grid item xs={2} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Issued On"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{shrink: true,  style: { paddingLeft: "12px" } }}
                fullWidth
                type="date"
                onChange={event => ApplicantState.setissuedOn(event.target.value)}
                value={Applicant.issuedOn}
              />
            </Grid>
            <Grid item xs={2} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Center Assigned"
                variant="outlined"
                color="primary"
                select
                required
                error={requiredFields.get().centerAssignedError}
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setcenterAssigned(event.target.value)}
                value={Applicant.centerAssigned}
              >
                {CenterList.map(center => {
                  return <MenuItem value={center} key={center}>{center}</MenuItem>
                })}
              </TextField></Grid>
            <Grid item xs={6} style={{ padding: "1vh 1vw" }}>
              <TextField
                label="Remarks"
                variant="outlined"
                color="primary"
                InputProps={{ style: { borderRadius: '30px' } }}
                inputProps={{ style: { paddingLeft: '24px' } }}
                InputLabelProps={{ style: { paddingLeft: "12px" } }}
                fullWidth
                onChange={event => ApplicantState.setremarks(event.target.value)}
                value={Applicant.remarks}
              />
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <label htmlFor="contained-button-file" id="labelUploadPhoto" style={{ width: "90%" }}>
                <input accept="image/*" id="contained-button-file" type="file" style={{ display: "none" }} onChange={(event) => handlePhotoUpload(event, false)} />
                <Button variant="outlined" component="span" fullWidth size="large" startIcon={<AddAPhoto />} aria-label="upload picture" style={{ borderRadius: '30px', padding: '14px' }}>
                  Photo
                </Button>
              </label>
            </Grid>
            <Grid item xs={2} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <label htmlFor="contained-button-file1" id="labelUploadFile" style={{ width: "90%" }}>
                <input accept="image/*" id="contained-button-file1" multiple type="file" style={{ display: "none" }} onChange={(event) => handleDocumentsUpload(event, false)} />
                <Button variant="outlined" color="primary" component="span" fullWidth size="large" startIcon={<UploadFile />} aria-label="upload files" style={{ borderRadius: '30px', padding: '14px' }}>
                  Upload
                </Button>
              </label>
            </Grid>
            {Applicant.documents.length > 0 &&
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ width: 'fit-content', height: '120px' }}>
                  {Applicant.documents.map((document, i) => {
                    return <span key={i}>
                      <img src={document} alt="" id={"image-" + i} key={"image-" + i} style={{ height: '120px', margin: '0 1vw', cursor: 'pointer', borderRadius: '15px' }} onClick={(event, i) => { handleClickImage(event) }} />
                      <Menu
                        key={"menu-" + i}
                        anchorEl={ApplicantState.FormValue.imageInteracted}
                        open={Boolean(ApplicantState.FormValue.imageInteracted)}
                        onClose={handleImageMenuClose}
                      >
                        <MenuItem id={"preview-" + i} key={"preview-" + i} onClick={() => handleImagePreview("new")}>Preview</MenuItem>
                        <MenuItem id={"delete-" + i} key={"delete-" + i} onClick={() => handleImageRemove("new")}>Delete</MenuItem>
                      </Menu>
                    </span>
                  })}
                </div>
              </Grid>
            }
            <Grid item xs={3}>
            </Grid>
            <Grid item xs={6}>
              <Grid container>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    size="large"
                    style={{ width: "100%", margin: "2vh 1vw 1vh", borderRadius: '30px' }}
                    onClick={handleSubmitNewApplicant}
                    disabled={requiredFields.get().disableSubmitButtom}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="standard"
                    size="large"
                    style={{ width: "50%", margin: "2vh 1vw 1vh", height: 'fit-content', background: '#fff', color: '#7f354e' }}
                    onClick={() => ApplicantState.clearFields()}
                  >
                    Clear Form
                  </Button>
                </Grid>
              </Grid>

            </Grid>
            <Grid item xs={3}>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default observer(ApplicantFormPage);
