import * as EditApplicantState from "../States/EditApplicantState";
import { fetchApplicantUsingApplicantNoForEditApplicant,fetchApplicantUsingApplicantNameForEditApplicant } from "../Logics/DatabaseReads";
import {setSuccess, setError} from "../States/GlobalState";
import {saveNewImagesOnS3,editApplicantEntry,getFileNameFromImageURL } from "../Logics/DatabaseUpdates";
import {handleSuccessCloseButton, handleErrorCloseButton} from "./Alert";

export const handleApplicantSearch = async() => {
    let fetchedData = await fetchApplicantUsingApplicantNoForEditApplicant();
    if (fetchedData) {
      EditApplicantState.setfetchedData(fetchedData);
      EditApplicantState.setapplicantIDExist(true);
      return true;
    }
    else{
      EditApplicantState.setapplicantID("");
      return false;
    }
}

const formFields = (ApplicantO) => {
  let documentNames = [];
  let applicantPhotoName = "";
  ApplicantO.documents.map((value,i)=>{
    if(value.includes("blob")){
      documentNames.push(getFileNameFromImageURL(value));
    }else{
      documentNames.push(value);
    }
    return documentNames;
  })
  if(ApplicantO.applicantPhotoName===""){
    applicantPhotoName = ApplicantO.applicantPhoto;
  }else{
    applicantPhotoName = ApplicantO.applicantPhotoName;
  }
  
  return {
    "applicantID": ApplicantO.applicantID,
    "date": ApplicantO.date,
    "applicantName": ApplicantO.applicantName,
    "age": ApplicantO.age,
    "address" : ApplicantO.address,
    "occupation" : ApplicantO.occupation,
    "mobileNo" : ApplicantO.mobileNo,
    "landlineNo" : ApplicantO.landlineNo,
    "nativePlace" : ApplicantO.nativePlace,
    "familyMembersCount" : ApplicantO.familyMembersCount,
    "monthlyIncomeFamily" : ApplicantO.monthlyIncomeFamily,
    "caste" : ApplicantO.caste,
    "doctor": ApplicantO.doctor,
    "dialysisStartDate": ApplicantO.dialysisStartDate,
    "memberRef": ApplicantO.memberRef,
    "discussedOn": ApplicantO.discussedOn,
    "issuedOn": ApplicantO.issuedOn,
    "passedBy": ApplicantO.passedBy,
    "rejected": ApplicantO.rejected,
    "remarks" : ApplicantO.remarks,
    "applicantPhoto" : applicantPhotoName,
    "centerAssigned" : ApplicantO.centerAssigned,
    "rationCardColor" : ApplicantO.rationCardColor,
    "documents" : documentNames,
    "rateApproved":ApplicantO.rateApproved,
    "dialysisCount":ApplicantO.dialysisCount,
  }
};

export const handleSubmitEditApplicant = async() => {
  setSuccess({showSuccess:true,successMsg:"Updating Patient details",typeOfSuccess:""})
  let ApplicantO = EditApplicantState.FormValue.details;
  // checking mobileNo digits:
  let num = Number(ApplicantO.mobileNo);
  let text = num.toString();
  let length = text.length;
  if(length !== 10){
    setError({showError:true,errorMsg:"Incorrect mobile no! Please verify and try again.",typeOfError:""})
    setTimeout(handleErrorCloseButton,2000);
  }
  else {
    let FormDataNewEntry = formFields(ApplicantO);
    try{
      let res1 = await saveNewImagesOnS3("documents",ApplicantO.documents,"edit");
      let res2 = await saveNewImagesOnS3("applicantPhoto",ApplicantO.applicantPhoto,"edit");
      if(res1&&res2){
        let resArray = await editApplicantEntry(FormDataNewEntry);
        if(resArray){
          EditApplicantState.clearFields();
          setSuccess({showSuccess:true,successMsg:"SUCCESS! Patient detials updated.",typeOfSuccess:"ApplicantSuccessfullyAdded"})
          setTimeout(handleSuccessCloseButton,2000);
          return true;
        }
      }
    }catch(e){
      console.log(e);
      setError({showError:true,errorMsg:"SORRY! Error occured while updating patient.",typeOfError:""})
      setTimeout(handleErrorCloseButton,2000);
    }
  }
}

export const handleApplicantSearchByName = async() => {
    let ApplicantName = EditApplicantState.FormValue.nameSearch.nameData;
    let fetchedData = await fetchApplicantUsingApplicantNameForEditApplicant(ApplicantName);
    if (fetchedData) {
      EditApplicantState.setoptionData(fetchedData);
      let newFetchData = [];
      fetchedData.map((data)=>{
       let a = {label: data.applicantName, applicantID: data.applicantID };
       newFetchData.push(a);
       return newFetchData;
      });
      EditApplicantState.setnameOption(newFetchData);
    }  
}

export const handleNameSelectedFromDropDown = (value) => {
  EditApplicantState.setnameSelected(value);
  if(!value){
      EditApplicantState.clearFields();
      EditApplicantState.setoptionData([]);
      EditApplicantState.setnameOption([]);
      EditApplicantState.setapplicantIDExist(false);
  }else {
      let id = value.applicantID;
      let applicantData = EditApplicantState.FormValue.nameSearch.optionData.find((data)=>{if(data.applicantID === id) {return data} else {return {}}})
      EditApplicantState.setfetchedData(applicantData);
      EditApplicantState.setapplicantIDExist(true);
  }
}