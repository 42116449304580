import { observable, action } from "mobx";

import { todayDate } from "../Helpers/General";

//fetchedDataA - DialysisData
export const FormValue = observable({
	inputDetails: {
		applicantID: "",
		mobileNo: "",
	},
	applicantDetails: {},
	newDialysisEntry: {
		dialysisID: new Date().getTime(),
		applicantID: "",
		applicantName: "",
		date: todayDate,
		amountPaid: "",
		remarks: "",
		centerAssigned: "",
	},
	fetchedDataA: [],
	applicantsForSameMobileNo: {
		itemList: [],
		showAlert: false,
	},
	componentSetup: "ApplicantSearch",
	nameSearch: {
		nameData: "",
		optionData: [],
		fetchData: false,
		nameOption: [],
		nameSelected:null,
	}

});

export const setapplicantID = action(input => {
	FormValue.inputDetails.applicantID = input;
	FormValue.newDialysisEntry.applicantID = input;
});

export const setmobileNo = action(input => {
	FormValue.inputDetails.mobileNo = input;
});

export const setapplicantDetails = action(input => {
	FormValue.applicantDetails = input;
	FormValue.newDialysisEntry.applicantID = input.applicantID;
	FormValue.newDialysisEntry.applicantName = input.applicantName;
	FormValue.newDialysisEntry.centerAssigned = input.centerAssigned;
	FormValue.newDialysisEntry.amountPaid = input.rateApproved;
});

export const setdialysisID = action(input => {
	FormValue.newDialysisEntry.dialysisID = input;
});

export const setdate = action(input => {
	FormValue.newDialysisEntry.date = input;
});

export const setamountPaid = action(input => {
	FormValue.newDialysisEntry.amountPaid = input;
});

export const setremarks = action(input => {
	FormValue.newDialysisEntry.remarks = input;
});

export const setcenterAssigned = action(input => {
	FormValue.newDialysisEntry.centerAssigned = input;
});

export const setfetchedDataA = action(input => {
	FormValue.fetchedDataA = input;
});

export const setapplicantsForSameMobileNo = action(input => {
	FormValue.applicantsForSameMobileNo.itemList = input.itemList;
	FormValue.applicantsForSameMobileNo.showAlert = input.showAlert;
});

export const setcomponentSetup = action(input => {
	FormValue.componentSetup = input;
});

export const clearFields = action(() => {
	FormValue.newDialysisEntry = {
		...FormValue.newDialysisEntry,
		dialysisID: new Date().getTime(),
		date: todayDate,
		amountPaid: "",
		remarks: "",
	}
})

export const setnameData = action(input => {
	FormValue.nameSearch.nameData = input;
});

export const setoptionData = action(input => {
	FormValue.nameSearch.optionData = input;
});

export const setnameOption = action(input => {
	FormValue.nameSearch.nameOption = input;
});

export const setnameSelected = action(input => {
	FormValue.nameSearch.nameSelected = input;
});