import {observer} from "mobx-react-lite";
import logo from "../logo.jpg";
import {TextField, Button} from "@mui/material";
import { GlobalState, setUsername, setPassword,	setNewPassword1, setNewPassword2, canWeSignIn, canWeResetPassword} from "../States/GlobalState";
import {signingIn, resetPassword} from "../Helpers/SignInSignUp";
import ErrorAlert from "../Components/HelperComp/ErrorAlert";
import './01_SignInSignUpPage.css';
import LoadingModal from "../Components/LoadingModal"

const LoginPage = () => {
	let {username, password} = GlobalState.login;
  let {newPassword1, newPassword2} = GlobalState.resetPassword;
	let {isResetPassword} = GlobalState.auth;
	let {showError} = GlobalState.errorObj;
	return (
		<div className="login-backdrop">
			<div className="login-card">
				<div className="login-card-header">
					<div
						style={{
							background: "#fff",
							width: "9vw",
							height: "9vw",
							borderRadius: "5vw",
							display: "flex",
							justifyContent: "center",
							alignItems: "center"
						}}
					>
						<div
							style={{
								width: "8vw",
								height: "8vw",
								borderRadius: "50%",
								background: `url(${logo})`,
								backgroundPosition: "50% 50%",
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat"
							}}
						></div>
					</div>
				</div>
				{isResetPassword ? (
					/* Reset Password */
					<div
						style={{
							height: "calc(100% - 5vw)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column"
						}}
					>
						<TextField
							label="New Password"
							variant="outlined"
							color="primary"
							style={{width: "25vw", margin: "2vh 0vw"}}
							type="password"
							onChange={event => setNewPassword1(event.target.value)}
							value={newPassword1}
						/>
						<TextField
							label="Confirm Password"
							variant="outlined"
							color="primary"
							style={{width: "25vw", margin: "2vh 0vw"}}
							onChange={event => setNewPassword2(event.target.value)}
							value={newPassword2}
						/>
						<Button
							variant="contained"
							size="large"
							style={{width: "25vw", margin: "2vh 0vw"}}
							onClick={resetPassword}
							disabled={!canWeResetPassword.get()}
						>
							Reset Password
						</Button>
					</div>
				) : (
					/* Sign In */
					<div
						style={{
							height: "calc(100% - 5vw)",
							display: "flex",
							alignItems: "center",
							justifyContent: "center",
							flexDirection: "column"
						}}
					>
						<TextField
							label="Username"
							variant="outlined"
							color="primary"
							style={{width: "25vw", margin: "-2vh 0 2vh 0vw"}}
							onChange={event => setUsername(event.target.value)}
							value={username}
						/>
						<TextField
							label="Password"
							variant="outlined"
							color="primary"
							style={{width: "25vw", margin: "2vh 0vw"}}
							type="password"
							onChange={event => setPassword(event.target.value)}
							value={password}
						/>
						<Button
							variant="contained"
							size="large"
							style={{width: "25vw", margin: "2vh 0vw"}}
							onClick={signingIn}
							disabled={!canWeSignIn.get()}
						>
							Sign In
						</Button>
					</div>
				)}
				<LoadingModal />
				{showError &&
					<ErrorAlert />
				}
			</div>
		</div>
	);
};

export default observer(LoginPage);
