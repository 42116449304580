import {TextField, Button,MenuItem} from "@mui/material";
import * as DialysisState from "../../States/DialysisState";
import * as ReportState from "../../States/ReportState";
import {handleApplicantSearchButtonClicked,handlePatientClickedForSameMobileNo} from "../../Helpers/DialysisHistoryProcessing";
import {observer} from "mobx-react-lite";
import {GlobalState} from "../../States/GlobalState";
import NameSearchInReports from "./NameSearchInReports";

const SearchByPatient = () => {
  let {setpatientSelected, setpatientParameter, setpatientDetails, searchButtonDisabled} = ReportState;
  let {inputDetails} = ReportState.FormValue;

  const handleSearchButtonClicked = () =>{
    if(GlobalState.applicantsForSameMobileNoshowAlert){
      handlePatientClickedForSameMobileNo(inputDetails.patientSelected);
    }else{
      handleApplicantSearchButtonClicked(inputDetails.patientParameter,inputDetails.patientDetails);
    }
  }

  let disabled = false;
  if(GlobalState.applicantsForSameMobileNoshowAlert && inputDetails.patientSelected===""){
    disabled=true
  }
  return (
      <>
        <TextField
          select
          label="Patient Parameter"
          variant="outlined"
          color="primary"
          style={{width: "25vw", margin: "2vh 0vw"}}
          onChange={e => setpatientParameter(e.target.value)}
          value={inputDetails.patientParameter}
        >
          <MenuItem value={"applicantName"} key={"applicantName"}>Applicant Name</MenuItem>
          <MenuItem value={"applicantID"} key={"applicantID"}>Applicant ID</MenuItem>
          <MenuItem value={"mobileNo"} key={"mobileNo"}>Mobile Number</MenuItem>
        </TextField>
        {inputDetails.patientParameter === "applicantName" ? <NameSearchInReports/> :
          <TextField
          label="Patient Details"
          variant="outlined"
          color="primary"
          style={{width: "25vw", margin: "2vh 0vw"}}
          onChange={e => setpatientDetails(e.target.value)}
          value={inputDetails.patientDetails}

        />}
        {GlobalState.applicantsForSameMobileNoshowAlert && <TextField
          select
          label="Patient List"
          variant="outlined"
          color="primary"
          style={{width: "25vw", margin: "2vh 0vw"}}
          onChange={(e) => setpatientSelected(e.target.value)}
          value={inputDetails.patientSelected}
        >
          {DialysisState.FormValue.applicantsForSameMobileNo.itemList.map(item=>
            {return <MenuItem key={item.applicantID} value={item.applicantID}>{item.applicantName}</MenuItem>}
          )
          }
        </TextField>}
        <Button
          variant="contained"
          size="large"
          style={{width: "25vw", margin: "2vh 0vw 1vh"}}
          onClick={handleSearchButtonClicked}
          disabled={searchButtonDisabled.get() || disabled}
        >
          Search
        </Button>
      </>
	);
};

export default observer(SearchByPatient);
