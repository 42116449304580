/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createApplicantDB = /* GraphQL */ `
  mutation CreateApplicantDB(
    $input: CreateApplicantDBInput!
    $condition: ModelApplicantDBConditionInput
  ) {
    createApplicantDB(input: $input, condition: $condition) {
      applicantID
      date
      applicantName
      age
      address
      occupation
      mobileNo
      landlineNo
      nativePlace
      familyMembersCount
      monthlyIncomeFamily
      caste
      doctor
      dialysisStartDate
      memberRef
      discussedOn
      issuedOn
      passedBy
      rejected
      remarks
      applicantPhoto
      centerAssigned
      rationCardColor
      documents
      rateApproved
      dialysisCount
      createdAt
      updatedAt
    }
  }
`;
export const updateApplicantDB = /* GraphQL */ `
  mutation UpdateApplicantDB(
    $input: UpdateApplicantDBInput!
    $condition: ModelApplicantDBConditionInput
  ) {
    updateApplicantDB(input: $input, condition: $condition) {
      applicantID
      date
      applicantName
      age
      address
      occupation
      mobileNo
      landlineNo
      nativePlace
      familyMembersCount
      monthlyIncomeFamily
      caste
      doctor
      dialysisStartDate
      memberRef
      discussedOn
      issuedOn
      passedBy
      rejected
      remarks
      applicantPhoto
      centerAssigned
      rationCardColor
      documents
      rateApproved
      dialysisCount
      createdAt
      updatedAt
    }
  }
`;
export const deleteApplicantDB = /* GraphQL */ `
  mutation DeleteApplicantDB(
    $input: DeleteApplicantDBInput!
    $condition: ModelApplicantDBConditionInput
  ) {
    deleteApplicantDB(input: $input, condition: $condition) {
      applicantID
      date
      applicantName
      age
      address
      occupation
      mobileNo
      landlineNo
      nativePlace
      familyMembersCount
      monthlyIncomeFamily
      caste
      doctor
      dialysisStartDate
      memberRef
      discussedOn
      issuedOn
      passedBy
      rejected
      remarks
      applicantPhoto
      centerAssigned
      rationCardColor
      documents
      rateApproved
      dialysisCount
      createdAt
      updatedAt
    }
  }
`;
export const createDialysisDB = /* GraphQL */ `
  mutation CreateDialysisDB(
    $input: CreateDialysisDBInput!
    $condition: ModelDialysisDBConditionInput
  ) {
    createDialysisDB(input: $input, condition: $condition) {
      dialysisID
      applicantID
      applicantName
      date
      amountPaid
      remarks
      centerAssigned
      createdAt
      updatedAt
    }
  }
`;
export const updateDialysisDB = /* GraphQL */ `
  mutation UpdateDialysisDB(
    $input: UpdateDialysisDBInput!
    $condition: ModelDialysisDBConditionInput
  ) {
    updateDialysisDB(input: $input, condition: $condition) {
      dialysisID
      applicantID
      applicantName
      date
      amountPaid
      remarks
      centerAssigned
      createdAt
      updatedAt
    }
  }
`;
export const deleteDialysisDB = /* GraphQL */ `
  mutation DeleteDialysisDB(
    $input: DeleteDialysisDBInput!
    $condition: ModelDialysisDBConditionInput
  ) {
    deleteDialysisDB(input: $input, condition: $condition) {
      dialysisID
      applicantID
      applicantName
      date
      amountPaid
      remarks
      centerAssigned
      createdAt
      updatedAt
    }
  }
`;
