import { observable, action, computed } from "mobx";
import { handleNameSelectedFromDropDown } from "../Helpers/DialysisHistoryProcessing";

import { todayDate } from "../Helpers/General";

export const FormValue = observable({
	inputDetails: {
		centerAssigned: "",
		date: todayDate,
		dateEnd: todayDate,
		patientParameter: "", //make ""
		patientDetails: "", //make ""
		patientSelected: "",
	},
	requestedData: [],
	nextTokenValue: null,
	dateReached: "",
	searchBy: "patient",
	// change above to ""
	componentSetup: "DataSearch",
	searchButtonUnable: false,

});

export const searchButtonDisabled = computed(() => {
	let { searchBy, inputDetails } = FormValue;
	if (searchBy === "dates") {
		if (inputDetails.date === "") {
			return true;
		} else {
			return false;
		}
	}
	else if (searchBy === "center") {
		if (inputDetails.date === "" || inputDetails.centerAssigned === "") {
			return true;
		} else {
			return false;
		}
	}
	else if (searchBy === "patient") {
		if (inputDetails.patientParameter === "" || inputDetails.patientDetails === "") {
			return true;
		} else {
			return false;
		}
	}
	else {
		return true;
	}
})

export const setcenterAssigned = action(input => {
	FormValue.inputDetails.centerAssigned = input;
});

export const setsearchBy = action(input => {
	FormValue.searchBy = input;
});

export const setdate = action(input => {
	FormValue.inputDetails.date = input;
});

export const setdateEnd = action(input => {
	FormValue.inputDetails.dateEnd = input;
});

export const setpatientParameter = action(input => {
	FormValue.inputDetails.patientParameter = input;
});

export const setpatientDetails = action(input => {
	FormValue.inputDetails.patientDetails = input;
});

export const setpatientSelected = action(input => {
	FormValue.inputDetails.patientSelected = input;
});

export const setapplicantDetails = action(input => {
	FormValue.applicantDetails = input;
	FormValue.newDialysisEntry.applicantID = input.applicantID;
	FormValue.newDialysisEntry.centerAssigned = input.centerAssigned;
	FormValue.newDialysisEntry.amountPaid = input.rateApproved;
});

export const setdialysisID = action(input => {
	FormValue.newDialysisEntry.dialysisID = input;
});

export const setamountPaid = action(input => {
	FormValue.newDialysisEntry.amountPaid = input;
});

export const setremarks = action(input => {
	FormValue.newDialysisEntry.remarks = input;
});

export const setfetchedDataA = action(input => {
	FormValue.fetchedDataA = input;
});

export const setapplicantsForSameMobileNo = action(input => {
	FormValue.applicantsForSameMobileNo.itemList = input.itemList;
	FormValue.applicantsForSameMobileNo.showAlert = input.showAlert;
});

export const setcomponentSetup = action(input => {
	FormValue.componentSetup = input;
});

export const setrequestedData = action(input => {
	FormValue.requestedData = input;
});

export const setnextTokenValue = action(input => {
	FormValue.nextTokenValue = input;
});

export const setdateReached = action(input => {
	FormValue.dateReached = input;
});

export const clearFields = action(() => {
	FormValue.newDialysisEntry = {
		...FormValue.newDialysisEntry,
		dialysisID: new Date().getTime(),
		date: todayDate,
		dateEnd: todayDate,
		amountPaid: "",
		remarks: "",
	}
})

export const resetsearchFields = action(() => {
	FormValue.inputDetails = {
		...FormValue.inputDetails,
		centerAssigned: "",
		date: todayDate,
		dateEnd: todayDate,
		patientParameter: "applicantID",
		patientDetails: "",
		patientSelected: "",
	}
	FormValue.searchBy = "";
	FormValue.requestedData = [];
	handleNameSelectedFromDropDown(null);
})
