/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:9c0a3f34-5037-482e-99d1-daeed3047451",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_4XxGCczIF",
    "aws_user_pools_web_client_id": "28ddjpb6mttf2gnu3h62mqdfq9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_appsync_graphqlEndpoint": "https://ber37wtdljbypfq5ajdgtydm4y.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-jwtlj2ag2rh4fjmgsu2ny6jdua",
    "aws_user_files_s3_bucket": "jyfdialysisiom44f625ad7149427b8b9c07d42fccee9a233220-dev",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
