import * as NewApplicantState from "../States/ApplicantState";
import { newApplicantEntry,saveNewImagesOnS3, updatePatientID } from "../Logics/DatabaseUpdates";
import { fetchPatientID } from "../Logics/DatabaseReads";
import {setSuccess, setError} from "../States/GlobalState";
import {handleSuccessCloseButton, handleErrorCloseButton} from "./Alert";

const formFields = (ApplicantN) => {
  let documentNames = []
  ApplicantN.documentsName.map((value,i)=>{
    documentNames.push(value);
    return documentNames;
  })
  return {
    "applicantID": ApplicantN.applicantID,
    "date": ApplicantN.date,
    "applicantName": ApplicantN.applicantName,
    "age": ApplicantN.age,
    "address" : ApplicantN.address,
    "occupation" : ApplicantN.occupation,
    "mobileNo" : ApplicantN.mobileNo,
    "landlineNo" : ApplicantN.landlineNo,
    "nativePlace" : ApplicantN.nativePlace,
    "familyMembersCount" : ApplicantN.familyMembersCount,
    "monthlyIncomeFamily" : ApplicantN.monthlyIncomeFamily,
    "caste" : ApplicantN.caste,
    "doctor": ApplicantN.doctor,
    "dialysisStartDate": ApplicantN.dialysisStartDate,
    "memberRef": ApplicantN.memberRef,
    "discussedOn": ApplicantN.discussedOn,
    "issuedOn": ApplicantN.issuedOn,
    "passedBy": ApplicantN.passedBy,
    "rejected": ApplicantN.rejected,
    "remarks" : ApplicantN.remarks,
    "applicantPhoto" : ApplicantN.applicantPhotoName,
    "centerAssigned" : ApplicantN.centerAssigned,
    "rationCardColor" : ApplicantN.rationCardColor,
    "documents" : documentNames,
    "rateApproved":ApplicantN.rateApproved,
    "dialysisCount":ApplicantN.dialysisCount,
}};

export const handleSubmitNewApplicant = async() => {
  setSuccess({showSuccess:true,successMsg:"Uploading New Patient details",typeOfSuccess:""})
  let ApplicantN = {...NewApplicantState.FormValue.details};
  ApplicantN.applicantName = ApplicantN.applicantName.toUpperCase();
// checking mobileNo digits:
    let num = Number(ApplicantN.mobileNo);
    let text = num.toString();
    let length = text.length;
    if(length !== 10){
      setError({showError:true,errorMsg:"Incorrect mobile no! Please verify and try again.",typeOfError:""})
      setTimeout(handleErrorCloseButton,2000);
    }
    else {
      let FormDataNewEntry = formFields(ApplicantN);
      try{
        let res1 = await saveNewImagesOnS3("documents",ApplicantN.documents,"new");
        let res2 = await saveNewImagesOnS3("applicantPhoto",ApplicantN.applicantPhoto,"new");
        if(res1&&res2){
          let resArray = await newApplicantEntry(FormDataNewEntry);
          if(resArray){
            let response = await updatePatientID();
            if(response){
              NewApplicantState.clearFields();
              setSuccess({showSuccess:true,successMsg:"SUCCESS! New patient added.",typeOfSuccess:"ApplicantSuccessfullyAdded"})
              setTimeout(handleSuccessCloseButton,2000);
              await fetchPatientID();
            }
          }
        }
      }catch(e){
        console.log(e);
        setError({showError:true,errorMsg:"SORRY! Error occured while adding new patient.",typeOfError:""})
        setTimeout(handleErrorCloseButton,2000);
      }
    }

}

export const handleFetchPatientIDCount = async () => {
  await fetchPatientID();
}
