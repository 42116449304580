import { observable, action, computed } from "mobx";

import { amountWithCommas } from "../Helpers/General";

export const FormValue = observable({
  details: {
    applicantID: "",
    date: "",
    applicantName: "",
    age: "",
    address: "",
    occupation: "",
    mobileNo: "",
    landlineNo: "",
    nativePlace: "",
    familyMembersCount: "",
    monthlyIncomeFamily: "",
    caste: "",
    doctor: "",
    dialysisStartDate: "",
    memberRef: "",
    discussedOn: "",
    issuedOn: "",
    passedBy: "",
    rejected: "",
    remarks: "",
    applicantPhoto: "",
    centerAssigned: "",
    rationCardColor: "",
    documents: [],
    rateApproved: "",
    dialysisCount: "",
    documentsName: [],
    applicantPhotoName: "",
  },
  imageInteracted: null,
  searchApplicant: false,
  applicantIDExist: false,
  nameSearch: {
    nameData: "",
    optionData: [],
    fetchData: false,
    nameOption: [],
    nameSelected: null,
  }
});

//actions
export const setfetchedData = action(input => {
  FormValue.details.date = input.date;
  FormValue.details.applicantName = input.applicantName.toUpperCase();
  FormValue.details.applicantID = input.applicantID;
  FormValue.details.age = input.age;
  FormValue.details.address = input.address;
  FormValue.details.occupation = input.occupation;
  FormValue.details.mobileNo = input.mobileNo;
  FormValue.details.landlineNo = input.landlineNo;
  FormValue.details.nativePlace = input.nativePlace;
  FormValue.details.familyMembersCount = input.familyMembersCount;
  FormValue.details.monthlyIncomeFamily = input.monthlyIncomeFamily;
  FormValue.details.caste = input.caste;
  FormValue.details.doctor = input.doctor;
  FormValue.details.dialysisStartDate = input.dialysisStartDate;
  FormValue.details.memberRef = input.memberRef;
  FormValue.details.discussedOn = input.discussedOn;
  FormValue.details.issuedOn = input.issuedOn;
  FormValue.details.passedBy = input.passedBy;
  FormValue.details.rejected = input.rejected;
  FormValue.details.remarks = input.remarks;
  FormValue.details.applicantPhoto = input.applicantPhoto;
  FormValue.details.centerAssigned = input.centerAssigned;
  FormValue.details.rationCardColor = input.rationCardColor;
  FormValue.details.documents = input.documents;
  FormValue.details.rateApproved = input.rateApproved;
  FormValue.details.dialysisCount = input.dialysisCount;

});

export const requiredFields = computed(() => {
  let { applicantID, applicantName, centerAssigned, mobileNo } = FormValue.details;
  let applicantIDError = false;
  let applicantNameError = false;
  let centerAssignedError = false;
  let mobileNoError = false;
  let disableSubmitButtom = false;
  if (applicantID === "") {
    applicantIDError = true;
  }
  if (applicantName === "") {
    applicantNameError = true;
  }
  if (centerAssigned === "") {
    centerAssignedError = true;
  }
  if (mobileNo === "") {
    mobileNoError = true;
  }
  if (applicantID === "" || applicantName === "" || mobileNo === "" || centerAssigned === "") {
    disableSubmitButtom = true;
  }
  return { applicantIDError, applicantNameError, centerAssignedError, mobileNoError, disableSubmitButtom }
})


export const setdate = action(input => {
  FormValue.details.date = input;
});

export const setapplicantID = action(input => {
  FormValue.details.applicantID = input;
});

export const setapplicantName = action(input => {
  FormValue.details.applicantName = input.toUpperCase();
});

export const setage = action(input => {
  FormValue.details.age = input;
});

export const setaddress = action(input => {
  FormValue.details.address = input;
});

export const setoccupation = action(input => {
  FormValue.details.occupation = input;
});

export const setmobileNo = action(input => {
  FormValue.details.mobileNo = input;
});

export const setlandlineNo = action(input => {
  FormValue.details.landlineNo = input;
});

export const setnativePlace = action(input => {
  FormValue.details.nativePlace = input;
});

export const setfamilyMembersCount = action(input => {
  FormValue.details.familyMembersCount = input;
});

export const setmonthlyIncomeFamily = action(input => {
  let newInput = amountWithCommas(input);
  FormValue.details.monthlyIncomeFamily = newInput;
});

export const setcaste = action(input => {
  FormValue.details.caste = input;
});

export const setdoctor = action(input => {
  FormValue.details.doctor = input;
});

export const setdialysisStartDate = action(input => {
  FormValue.details.dialysisStartDate = input;
});

export const setmemberRef = action(input => {
  FormValue.details.memberRef = input;
});

export const setdiscussedOn = action(input => {
  FormValue.details.discussedOn = input;
});

export const setissuedOn = action(input => {
  FormValue.details.issuedOn = input;
});

export const setpassedBy = action(input => {
  FormValue.details.passedBy = input;
});

export const setrejected = action(input => {
  FormValue.details.rejected = input;
});

export const setremarks = action(input => {
  FormValue.details.remarks = input;
});

export const setapplicantPhoto = action(input => {
  FormValue.details.applicantPhoto = input;
});

export const setapplicantSign = action(input => {
  FormValue.details.applicantSign = input;
});

export const setcenterAssigned = action(input => {
  FormValue.details.centerAssigned = input;
});

export const setrationCardColor = action(input => {
  FormValue.details.rationCardColor = input;
});

export const setdocuments = action(input => {
  FormValue.details.documents = input;
});

export const setdialysisCount = action(input => {
  FormValue.details.dialysisCount = input;
});

export const setrateApproved = action(input => {
  FormValue.details.rateApproved = input;
});

export const setimageInteracted = action(input => {
  FormValue.imageInteracted = input;
});

export const setsearchApplicant = action(input => {
  FormValue.searchApplicant = input;
});

export const setapplicantIDExist = action(input => {
  FormValue.applicantIDExist = input;
});


export const setdocumentsName = action(input => {
  FormValue.details.documentsName = input;
});

export const setapplicantPhotoName = action(input => {
  FormValue.details.applicantPhotoName = input;
});


export const clearFields = action(() => {
  if (FormValue.details.applicantPhoto !== "" && FormValue.details.applicantPhoto.includes("blob")) {
    URL.revokeObjectURL(FormValue.details.applicantPhoto)
  }
  if (FormValue.details.documents.length > 0) {
    FormValue.details.documents.forEach((url) => {
      if (url.includes("blob")) {
        URL.revokeObjectURL(url);
      }
    })
  }
  FormValue.details = {
    applicantID: "",
    date: "",
    applicantName: "",
    age: "",
    address: "",
    occupation: "",
    mobileNo: "",
    landlineNo: "",
    nativePlace: "",
    familyMembersCount: "",
    monthlyIncomeFamily: "",
    caste: "",
    doctor: "",
    dialysisStartDate: "",
    memberRef: "",
    discussedOn: "",
    issuedOn: "",
    passedBy: "",
    rejected: "",
    remarks: "",
    applicantPhoto: "",
    centerAssigned: "",
    rationCardColor: "",
    documents: [],
    rateApproved: "",
    dialysisCount: "",
    documentsName: [],
    applicantPhotoName: "",

  }
  FormValue.nameSearch = {
    nameData: "",
    optionData: [],
    fetchData: false,
    nameOption: [],
    nameSelected: null
  }
})

export const setnameData = action(input => {
  FormValue.nameSearch.nameData = input;
});

export const setoptionData = action(input => {
  FormValue.nameSearch.optionData = input;
});

export const setnameOption = action(input => {
  FormValue.nameSearch.nameOption = input;
});

export const setnameSelected = action(input => {
  FormValue.nameSearch.nameSelected = input;
});