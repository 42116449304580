import {
    setNewContentCount, setLoadingModal, setAddingImage,allContentProcessed,
    setNewContentProcessed,setNewContentTwoKURL,setEditContentTwoKURL,UploadState,
} from "../States/UploadState";
import Compressor from "compressorjs";



export const handlePhotoUpload = (event,edit) => {
    if (event.target.files.length > 0) {
      setAddingImage('photo');
      setNewContentCount(event.target.files.length);
      setUpImageResizeValues(event.target.files[0],edit);
    }
    else{
      console.log("Error in Photo Upload");
    }
};

export const handleDocumentsUpload = (event,edit) => {
    if (event.target.files.length > 0) {
      setAddingImage('documents');
      setNewContentCount(event.target.files.length);
      for (let i = 0; i < event.target.files.length; i++) {
          let file = event.target.files[i];
          setUpImageResizeValues(file,edit);
      }
    }
    else{
      console.log("Error in Photo Upload");
    }
};

export const setUpImageResizeValues = (file,edit) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    let resetParams = {
        width: "",
        height: "",
        imageIsWide: null,
        imageIsLong: null,
        widthReset: null,
        heightReset: null,
        edit:edit,
    };
    reader.onload = (e) => {
        let img = new Image();
        img.src = e.target.result;
        img.onload = () => {
            resetParams.height = img.height;
            resetParams.width = img.width;
            img = null;
            compressImagesFromResizeValues(file, DecideImageResizeValues(resetParams));
        }
    }
};

export const DecideImageResizeValues = (resetParams) => {
    const {height, width} = resetParams;
    if (height > width) {
        if (height > 2160) {
            resetParams.heightReset = true;
        }
        resetParams.imageIsLong = true;
    } else {
        if (width > 2160) {
            resetParams.widthReset = true;
        }
        resetParams.imageIsWide = true;
    }
    return resetParams;
};

export const compressImagesFromResizeValues = (file, resetParams) => {
    if (resetParams.imageIsLong) {
        if (resetParams.heightReset) {
            LongImageResizedTo2K(file, resetParams.edit);
        } else {
            UnResizedCompressedImage(file, resetParams.edit);
        }
    }
    if (resetParams.imageIsWide) {
        if (resetParams.widthReset) {
            WideImageResizedTo2K(file, resetParams.edit);
        } else {
            UnResizedCompressedImage(file, resetParams.edit);
        }
    }
}

export const LongImageResizedTo2K = (file,edit) => {
    new Compressor(file,{
        quality:0.7,
        height:2160,
        mimeType:"image/jpeg",
        success(file) {
            saveFileInArray(file,edit)
        }
    })
};

export const WideImageResizedTo2K = (file,edit) => {
    new Compressor(file,{
        quality:0.7,
        width:2160,
        mimeType:"image/jpeg",
        success(file) {
            saveFileInArray(file,edit)
        }
    })
};

export const UnResizedCompressedImage = (file,edit) => {
    new Compressor(file,{
        quality:0.7,
        mimeType:"image/jpeg",
        success(file) {
            saveFileInArray(file,edit)
        }
    })
};

export const saveFileInArray = (file,edit) => {
    let url = URL.createObjectURL(file);
    if(edit){
        setEditContentTwoKURL(url);
    }else{
        setNewContentTwoKURL(url);
    }
    setNewContentProcessed(UploadState.newContentProcessed+1);
    if(allContentProcessed.get()){
        if(edit){
          setLoadingModal(false);
          setAddingImage('');
        }else{
            setLoadingModal(false);
            setAddingImage('');
        }
    }
};
