import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import * as graphqlOperationQueries from "./Logics/DatabaseReads";
import * as graphqlOperationMutation from "./Logics/DatabaseUpdates";
import Amplify from "aws-amplify";
import awsConfig from "./aws-exports";

Amplify.configure(awsConfig);

const theme = createTheme({
  palette: {
    primary: {
      main:"#7f354e",
    },
    secondary: {
      main:"#fff"
    }
  },
});

window.queries = graphqlOperationQueries;
window.mutations = graphqlOperationMutation;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
